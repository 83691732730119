import { Component, Input, AfterViewInit, ElementRef } from '@angular/core';

@Component({
    selector: 'sfx-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit {
    private _disableDividers = false;
    private _disableBackground = false;

    private _observer: IntersectionObserver | undefined;
    constructor(private hostElement: ElementRef) {}

    @Input()
    set disableDividers(value: boolean | string) {
        this._disableDividers = typeof value === 'boolean' ? value : !!(value === '' || value);
    }
    get disableDividers(): boolean {
        return this._disableDividers;
    }

    @Input()
    set disableBackground(value: boolean | string) {
        this._disableBackground = typeof value === 'boolean' ? value : !!(value === '' || value);
    }
    get disableBackground(): boolean {
        return this._disableBackground;
    }

    ngAfterViewInit(): void {

        this._observer = new IntersectionObserver(([e]) => {

            e.target.classList.toggle('sticky', e.intersectionRatio < 1);
        },
        { threshold: [0, 1] });
        this._observer.observe(this.hostElement.nativeElement);
    }
}
