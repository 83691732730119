import { AfterContentInit, Component, Inject, OnInit } from '@angular/core';

import { MediaService } from '@core/services/media.service';
import { MatDialog } from '@angular/material/dialog';

import { NotificationService } from '@core/services/notifications.service';

import { ApiResponseModel as ApiResponse } from '@shared/models/services/api.response.model';
import { SfxDialog, DialogResponse } from '@shared/components/dialog/dialog.component';

import { catchError, filter, mergeMap, of } from 'rxjs';

import {
    EditApiKeyContentData, EditApiKeyDialogContentComponent
} from '@feature/settings/components/api-key-settings/dialogs/edit-api-key-dialog-content.component';

import { ApiKeyModel as ApiKey } from '@shared/models/api-key.model';

@Component({
    selector: 'sfx-api-key-settings',
    templateUrl: './api-key-settings.component.html',
    styleUrls: ['./api-key-settings.component.scss']
})
export class ApiKeySettingsComponent implements OnInit, AfterContentInit {

    public apiDocsUrl = '';

    apiKeys: ApiKey[] = [];
    initialLoad = false;

    constructor(
        public media: MediaService,
        private matDialog: MatDialog,
        private notificationService: NotificationService,
        @Inject('apiKeyService') private apiKeyService: any,
        @Inject('apiKeysService') private apiKeysService: any,
        @Inject('config') private config: any
    ) {}

    ngOnInit(): void {

        this.apiDocsUrl = this.config.apiUrl + 'docs';

        this.getApiKeys().then(() => {

            this.initialLoad = true;
        });
    }

    ngAfterContentInit(): void {}

    private getApiKeys() {

        return this.apiKeysService.get().then((response: ApiResponse<any[]>) => {

            this.apiKeys = response.data;
        });
    }

    public createApiKey() {

        this.matDialog.open(SfxDialog, {
            panelClass: 'sfx-dialog-container-unthemed',
            data: {
                title: 'Create API Key',
                confirm: 'Create',
                close: 'Cancel',
                context: { apiKey: { name: '' } },
                content: EditApiKeyDialogContentComponent
            }
        }).afterClosed()
            .pipe(
                filter((context: DialogResponse<EditApiKeyContentData>) => context?.confirmed),
                mergeMap((context: DialogResponse<EditApiKeyContentData>) => this.apiKeyService.create(context.state.apiKey)),
                catchError(() => {

                    return of(null);
                })
            )
            .subscribe({
                next: (result) => {

                    if (result) {
                        this.notificationService.success('Success! Your API key has been created.');
                    }

                    this.getApiKeys();
                }
            });
    }

    public editApiKey(apiKey: ApiKey): void {

        this.matDialog.open(SfxDialog, {
            panelClass: 'sfx-dialog-container-unthemed',
            data: {
                title: 'Edit API Key',
                confirm: 'Save',
                close: 'Cancel',
                context: { apiKey },
                content: EditApiKeyDialogContentComponent
            }
        }).afterClosed()
            .pipe(
                filter((context: DialogResponse<EditApiKeyContentData>) => context?.confirmed),
                mergeMap((context: DialogResponse<EditApiKeyContentData>) => this.apiKeyService.update(context.state.apiKey)),
                catchError(() => {

                    return of(null);
                })
            )
            .subscribe({
                next: () => {

                    this.getApiKeys();
                }
            });
    }

    public deleteApiKey(apiKey: ApiKey): void {

        this.matDialog.open(SfxDialog, {
            panelClass: 'sfx-dialog-container-unthemed',
            data: {
                title: 'Are you sure you want to delete this API key?',
                confirm: 'Yes',
                close: 'No'
            }
        }).afterClosed()
            .pipe(
                filter((context: DialogResponse<any>) => context?.confirmed),
                mergeMap(() => this.apiKeyService.delete(apiKey)),
                catchError(() => {

                    return of(null);
                })
            )
            .subscribe({
                next: () => {

                    this.getApiKeys();
                }
            });
    }

}
