(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('AccountFeedController', AccountFeedController);

    function AccountFeedController($rootScope, $scope, $stateParams, $state, $timeout, $window, $mdMedia, $transitions, config, events, model, account, messageService, utils, email, meeting, interactiongroup, $mdDialog, sfWalkthrough, sfDiscardDialog, filterService, helperFunctionsService) {

        let thread = null;

        $scope.showSpeedDialTooltips = !$window.touch; // They use hover states (aka mouseenter/...) and that just breaks on mobile so better to disable it
        $scope.apiUrl = config.apiUrl;
        $scope.model = model;
        $scope.message = {
            account: $stateParams.id
        };
        $scope.mode = config.mode;
        $scope.opened = false;
        $scope.feed = null;
        $scope.feedDetails = {};
        $scope.isSameDay = utils.isSameDay;

        // Feed filter stuff
        $scope.filterOptions = ['Emails', 'Internal notes', 'Meetings', 'Phone calls', 'Link clicks', 'Website visits', 'Team updates'];
        $scope.feedFilterCustomers = {};
        $scope.allCustomersSelected = false;
        $scope.feedFilterInteractionTypes = {};
        $scope.allInteractionTypesSelected = false;
        // Fill the feed filters like this since the data in the local storage looks different due to backwards compatibility reasons
        // We fill in the customers on init since we need to wait for the account to be able to fill
        filterService.getAccountFeedTypesFilter().forEach(function (option) {

            $scope.feedFilterInteractionTypes[option] = true;
        });
        setAllInteractionTypesSelected();

        let editingMessage = false;
        const typesMap = {
            'Emails': 'email',
            'Internal notes': 'message',
            'Meetings': 'meeting-live',
            'Phone calls': 'meeting-phone',
            'Link clicks': 'forward',
            'Website visits': 'webpage',
            'Team updates': ['user_added', 'user_removed', 'account_created']
        };

        if ($rootScope.history[$rootScope.history.length - 1].fromState.name.startsWith('accounts.')) {
            $rootScope.history.pop();
        }

        // By default md-menu-bar adds `md-dense` classes which we don't want so we remove them here
        // bit hacky but I tried a directive and it was always to quick
        $timeout(function () {

            return angular.element('.feed-filter md-menu-content').removeClass('md-dense');
        });

        if ($scope.account) {
            init();
            setFilterWatchers();
            $scope.$on(events.account.loaded, onAccountLoaded);
        }
        else {
            const unbindLoadedWatcher = $scope.$on(events.account.loaded, function (event, loadedAccount) {

                $scope.account = loadedAccount;
                unbindLoadedWatcher();
                init();
                setFilterWatchers();
                return $scope.$on(events.account.loaded, onAccountLoaded);
            });
        }

        $scope.$watch('message.body', function (newValue) {

            if (newValue) {
                model.disableFab = true;
            }
            else {
                model.disableFab = false;
            }
        });

        $scope.$on(events.refreshData, reload);
        $scope.$on(events.account.updated, function (evt, data) {

            if (data && !data.fetchFeed) {
                return;
            }

            return reload();
        });

        function setFilterWatchers() {
            /**
             * `init` relies on this watch to trigger, so take that in mind when making changes
             */
            $scope.$watch('feedFilterCustomers', function (newValue) {

                if (!newValue || !$scope.account) {
                    return;
                }

                setAllCustomersSelected();

                $timeout.cancel(thread);
                // Add a small time out to allow for quick (de)selecting of checkboxes
                // Without spamming requests
                thread = $timeout(function () {

                    // Convert new data structure to list of ids
                    const customerIds = [];
                    Object.entries($scope.feedFilterCustomers).forEach(function (entry) {

                        if (entry[1]) {
                            customerIds.push(entry[0]);
                        }
                    });

                    // Only store customer filter when not all customers are selected to prevent clogging local storage
                    if (customerIds.length === Object.keys($scope.feedFilterCustomers).length) {
                        filterService.setAccountFeedCustomersFilter($scope.account.id);
                    }
                    else {
                        filterService.setAccountFeedCustomersFilter($scope.account.id, customerIds);
                    }

                    // Reset `load more` button since we are basically doing a reload
                    $scope.everythingLoaded = false;
                    $scope.nothingMore = false;

                    // Use `get` not `reload` since `reload` uses the existing limit which does not make sense here
                    return get(undefined, false);
                }, $scope.feed === null ? 0 : 750);
            }, true);

            // Add watches for feed filters
            $scope.$watch('feedFilterInteractionTypes', function (newValue) {

                if (!newValue) {
                    return;
                }

                setAllInteractionTypesSelected();

                $timeout.cancel(thread);
                // Add a small time out to allow for quick (de)selecting of checkboxes
                // Without spamming requests
                thread = $timeout(function () {

                    // Convert new data structure to list of strings
                    const accountFeedFilter = [];
                    Object.entries($scope.feedFilterInteractionTypes).forEach(function (entry) {

                        if (entry[1]) {
                            accountFeedFilter.push(entry[0]);
                        }
                    });

                    filterService.setAccountFeedTypesFilter(accountFeedFilter);

                    // Reset `load more` button since we are basically doing a reload
                    $scope.everythingLoaded = false;
                    $scope.nothingMore = false;

                    // Use `get` not `reload` since `reload` uses the existing limit which does not make sense here
                    return get(undefined, false);
                }, $scope.feed === null ? 0 : 750);
            }, true);
        }

        function setAllInteractionTypesSelected() {

            // Comparing to filterOptions.length because feedFilterInteractionTypes doesn't always contain non selected items
            if ($scope.filterOptions.length === Object.values($scope.feedFilterInteractionTypes).filter((obj) => obj === true).length) {
                $scope.allInteractionTypesSelected = true;
            }
            else {
                $scope.allInteractionTypesSelected = false;
            }
        }

        $scope.selectOrDeselectAllInteractionTypes = function () {

            if ($scope.allInteractionTypesSelected) {
                Object.keys($scope.feedFilterInteractionTypes).forEach(function (key) {

                    $scope.feedFilterInteractionTypes[key] = false;
                });
            }
            else {
                // Using filterOptions because feedFilterInteractionTypes doesn't always contain non selected items
                $scope.filterOptions.forEach(function (key) {

                    $scope.feedFilterInteractionTypes[key] = true;
                });
            }
        };

        function setAllCustomersSelected() {

            if ($scope.account.customers.length === Object.values($scope.feedFilterCustomers).filter((obj) => obj === true).length) {
                $scope.allCustomersSelected = true;
            }
            else {
                $scope.allCustomersSelected = false;
            }
        }

        $scope.selectOrDeselectAllCustomers = function () {

            if ($scope.allCustomersSelected) {
                Object.keys($scope.feedFilterCustomers).forEach(function (key) {

                    $scope.feedFilterCustomers[key] = false;
                });
            }
            else {
                Object.keys($scope.feedFilterCustomers).forEach(function (key) {

                    $scope.feedFilterCustomers[key] = true;
                });
            }
        };

        // Listen to the account:loaded event to ensure we always have an up-to-date version of the account on scope
        // This ensures we can do stuff like mentioning a newly added team member
        // We also use this event to check if any customers got added to/removed from the account so we can update the account feed filter to include/exclude these contacts
        // Also make sure we call init before registering this function as an event handler to avoid timing issue with initializing the feed filter object
        function onAccountLoaded(event, loadedAccount) {

            const removedCustomerIds = (!$scope.account || !$scope.account.customers) ? [] : $scope.account.customers.filter(function (customer) {

                return !loadedAccount.customers.some(function (loadedAccountCustomer) {

                    return loadedAccountCustomer.id === customer.id;
                });
            }).map(function (customer) {

                return customer.id;
            });
            const addedCustomerIds = loadedAccount ? loadedAccount.customers.filter(function (customer) {

                return !$scope.account.customers.some(function (oldAccountCustomer) {

                    return oldAccountCustomer.id === customer.id;
                });
            }).map(function (customer) {

                return customer.id;
            }) : [];

            $scope.account = loadedAccount;

            removedCustomerIds.forEach(function (removedCustomerId) {

                $scope.feedFilterCustomers[removedCustomerId] = false;
            });

            addedCustomerIds.forEach(function (addedCustomerId) {

                $scope.feedFilterCustomers[addedCustomerId] = true;
            });
        }

        $scope.openFeedFilter = function () {

            return angular.element('.feed-filter .feed-filer_opener').click();
        };

        $scope.isAccountFeedFilterApplied = function () {

            return (!!$scope.account && !!getFilterContacts()) || filterService.isAccountFeedTypesFilterApplied();
        };

        $scope.goToAccountInfo = function () {

            if ($mdMedia('gt-sm')) {
                return $scope.$parent.toggleRight();
            }

            return $state.go('accounts.account.info');
        };

        $scope.openWebsite = helperFunctionsService.openWebsite;

        $scope.showPerson = function (person) {

            if (person.id) {
                if (person.type === 'user') {
                    return $state.go('user', { id: person.id });
                }

                return $state.go('contact', { id: person.id });
            }
        };

        $scope.onNewMessageBoxDescriptionChange = function ($event) {

            $scope.message.body = $event.description;
            $scope.message.mentions = $event.mentions;
            editingMessage = ($scope.message.body && $scope.message.body !== '');
        };

        $scope.createMessage = function createMessage() {

            if (!$scope.message.body || $scope.message.body === '') {
                return;
            }

            const messageClone = angular.copy($scope.message);

            $scope.message.body = '';
            $scope.message.mentions = [];

            return messageService.create(messageClone).then(function () {

                editingMessage = false;
                getFeedAfter(true);
                $rootScope.$broadcast(events.account.updated, { fetchFeed: false });
            });
        };

        $scope.onMessageDescriptionChange = function ($event, interaction) {

            interaction.body = $event.description;
            interaction.mentions = $event.mentions;

            editingMessage = (interaction.body && interaction.body !== '');
        };

        $scope.editMessage = function (interaction) {

            if (!interaction.body) {
                return;
            }

            const messageClone = {
                body: interaction.body,
                mentions: interaction.mentions,
                account: $scope.account.id
            };

            return messageService.update(interaction.id, messageClone).then(function () {

                editingMessage = false;

                interaction.editing = false; // Hide editor and show message again
                interaction.description = interaction.body; // Update message's description, the one that shows in the feed (body is from the editor)

                $rootScope.$broadcast(events.account.updated, { fetchFeed: false });
            });
        };

        // This allows the listItemDetail to expand downwards
        $scope.showHideFullHeader = function ($event) {

            const listItemDetail = angular.element($event.currentTarget).find('.list-item-detail');

            listItemDetail.toggleClass('ellipsis');
            listItemDetail.css({ 'whiteSpace': listItemDetail.css('white-space') === 'normal' ? 'nowrap' : 'normal' });
        };

        $scope.showHideDetail = function (interaction) {

            let detail = $scope.feedDetails[interaction.type + interaction.id];

            if (detail) {
                detail.show = !detail.show;
            }
            else {
                $scope.feedDetails[interaction.type + interaction.id] = interaction;

                detail = $scope.feedDetails[interaction.type + interaction.id];
                detail.id = interaction.id;
                detail.show = true;

                if (interaction.type === 'email') {

                    detail.email.addresses.allTo = (interaction.email.addresses.to || []);
                    if (interaction.email.addresses.cc) {
                        detail.email.addresses.allTo = [...detail.email.addresses.allTo, ...interaction.email.addresses.cc];
                    }

                    detail.email.addresses.allTo = detail.email.addresses.allTo.map(function (toRecipient) {

                        return (toRecipient.name + ' <' + toRecipient.email + '>') || toRecipient.email;
                    }).join(', ');

                    detail.email.text_body = getBody(detail.email.body, 'text/plain');

                    let htmlBody = getBody(detail.email.body, 'text/html');

                    if (htmlBody) {
                        detail.email.html = true;
                        detail.email.files.forEach(function (file) {

                            if (file.is_embedded && file.content_disposition === 'inline') {
                                // eslint-disable-next-line unicorn/prefer-string-slice
                                htmlBody = htmlBody.replace(new RegExp('cid:' + file.content_id.substring(1, file.content_id.length - 1), 'g'), config.apiUrl + 'files/' + file.file_id + '?email_account_id=' + interaction.email_account_id);
                            }
                        });

                        // Open links in separate window
                        htmlBody = htmlBody.replace(/<a/g, '<a target="_blank" ');

                        detail.email.html_body = htmlBody;
                    }

                    for (let i = detail.email.files.length - 1; i >= 0; --i) {
                        if (detail.email.files[i].is_embedded) {
                            detail.email.files.splice(i, 1);
                        }
                    }
                }
                else if (interaction.type === 'meeting-live' || interaction.type === 'meeting-phone' || interaction.type === 'meeting') {
                    return meeting.get(interaction.id, true).then(function (response) {

                        // eslint-disable-next-line no-shadow
                        const meeting = response.data;

                        if (meeting.notes) {
                            meeting.notes = fixLinks(meeting.notes);
                        }

                        if (meeting.description) {
                            meeting.description = fixLinks(meeting.description);
                        }

                        detail.meeting = meeting;
                    });
                }
                else if (interaction.type === 'webpage') {
                    return interactiongroup.get(interaction.id).then(function (response) {

                        detail.pages = response.data;
                        detail.max_duration = Math.max(...response.data.map(function (page) {

                            return page.duration;
                        }));

                        let tempTotal = 0;
                        for (let i = 0; i < response.data.length; ++i) {
                            tempTotal += response.data[i].duration;
                        }

                        detail.total_duration = tempTotal;
                        detail.totalDurationDescription = detail.total_duration < 60 * 1000 ? (detail.total_duration < 1000 ? '' : 'seconds') : 'minutes';
                    });
                }
                else if (interaction.type === 'forward') {
                    return interactiongroup.get(interaction.id).then(function (response) {

                        detail.pages = response.data;
                    });
                }
            }
        };

        $scope.strip = function (html) {

            const element = new DOMParser().parseFromString(html, 'text/html').firstChild;
            element.querySelectorAll('li').forEach( function (elem) {
                elem.outerHTML = '<div> ' + elem.innerHTML + '</div>';
            });

            element.querySelectorAll('div, p').forEach( function (elem) {
                elem.innerHTML = ' ' + elem.innerHTML;
            });

            return element.textContent || '';
        };

        function fixLinks(html) {

            const element = new DOMParser().parseFromString(html, 'text/html').firstChild;
            element.querySelectorAll('a').forEach( function (a) {
                a.setAttribute('target', '_blank');
                a.setAttribute('rel', 'noopener');
            });

            return element.innerHTML;
        }

        function getBody(bodies, type) {

            const body = bodies.find(function (item) {

                return (item.type === type);
            });

            if (body) {
                return body.content;
            }

            return null;
        }

        $scope.removeEmail = function (index, mail, $event) {

            $event.stopPropagation();
            $scope.feed.splice(index, 1);

            return account.removeEmail($scope.account.id, mail.email_message_id).then(function () {

                return $rootScope.$broadcast(events.account.updated);
            });
        };

        $scope.openMenuOnTouch = function ($event) {

            if ($window.touch) {
                $timeout(function () {

                    return angular.element($event.target).siblings('md-menu').children('md-icon').click();
                }, 0, false);
            }
        };

        $scope.showDeleteMessageDialog = function (index, message) {

            const confirm = $mdDialog.confirm()
                .clickOutsideToClose(true)
                .textContent('Are you sure you want to delete this message?')
                .ok('Yes')
                .cancel('No');
            return $mdDialog.show(confirm).then(function () {

                $scope.feed.splice(index, 1);

                return messageService.remove(message.id).then(function () {

                    if ($mdMedia('gt-sm')) {
                        $rootScope.$broadcast(events.account.updated);
                        return $mdDialog.hide();
                    }

                    return $rootScope.$broadcast(events.account.updated);
                });
            });
        };

        $scope.reply = function reply(mail, $event) {

            $event.stopPropagation();

            return email.compose(email.formatRawEmailToComposeObject('reply', mail), 'reply');
        };

        $scope.replyAll = function replyAll(mail, $event) {

            $event.stopPropagation();

            return email.compose(email.formatRawEmailToComposeObject('replyAll', mail), 'replyAll');
        };

        $scope.forward = function forward(mail, $event) {

            $event.stopPropagation();

            return email.compose(email.formatRawEmailToComposeObject('forward', mail), 'forward');
        };

        $scope.getFeedBefore = function getFeedBefore() {

            let before;

            if ($scope.feed.length > 0) {
                before = $scope.feed[0].time;
            }

            $scope.everythingLoaded = false;
            $scope.nothingMore = false;
            // We want to prevent that people spam the 'Load More' button
            // It might trigger the same getFeed call twice, causing ng-repeat to throw duplicate errors
            $scope.loadingMore = true;

            return account.getFeed($stateParams.id, { before, types: getFilterTypes(), contacts: getFilterContacts() }).then(function (response) {

                if (!response.data || response.data.length < 10) {
                    $scope.everythingLoaded = true;
                    $scope.nothingMore = true;
                }

                $scope.feed = [...response.data, ...$scope.feed].filter((entry, idx, arr) => {
                    return arr.findIndex((d) => d.id === entry.id && d.type === entry.type) === idx;
                });

                $scope.loadingMore = false;
            }).catch(function () {

                $scope.loadingMore = false;
            });
        };

        $scope.hideElement = sfWalkthrough.hideElement;

        $scope.isWalkthroughShowing = sfWalkthrough.isShowing;

        $scope.getCurrentWalkthroughStepId = sfWalkthrough.getCurrentStepId;

        const removeStartTransitionHook = $transitions.onStart({}, (transition) => {

            if (!editingMessage) {
                return;
            }

            const toState = transition.to();
            const toParams = transition.params('to');

            return sfDiscardDialog.show(false, {}).then(function () {
                editingMessage = false;
                return $state.go(toState, toParams);
            }).catch(() => {
                transition.abort();
            });
        });

        $scope.showFeedWarningMessage = () => {

            if (!$scope.account) {
                return false;
            }

            return (getFilterContacts() || $scope.account.customers).length > 50;
        };

        $scope.$onDestroy = function () {

            removeStartTransitionHook();
        };

        function getFeedAfter(scrollDown) {

            let after;

            if ($scope.feed && $scope.feed.length > 0) {
                after = $scope.feed[$scope.feed.length - 1].time;
            }

            return account.getFeed($stateParams.id, { after, types: getFilterTypes(), contacts: getFilterContacts() }, true).then(function (response) {

                if (response.data.length === 0) {
                    return;
                }

                // If an interaction was updated replace otherwise just add to feed
                for (let i = 0; i < response.data.length; ++i) {
                    const newInteraction = response.data[i];
                    let shared = false;

                    for (let j = 0; j < $scope.feed.length; ++j) {
                        const oldInteraction = $scope.feed[j];

                        if (newInteraction.type + newInteraction.id === oldInteraction.type + oldInteraction.id) {
                            $scope.feed[j] = newInteraction;
                            shared = true;

                            break;
                        }
                    }

                    if (!shared) {
                        $scope.feed.push(newInteraction);
                    }
                }

                if (scrollDown) {

                    // Scroll to bottom of the feed
                    return $timeout(function () {

                        const lastInteractionElement = angular.element('#feed .interaction').last()[0];

                        if (lastInteractionElement) {
                            utils.scrollIntoView(lastInteractionElement);
                        }
                    }, 100);
                }
            });
        }

        function reload() {

            let limit;

            if (!$scope.feed || $scope.feed.length < 10) {
                limit = 10;
            }
            else {
                limit = $scope.feed.length;
            }

            return get(limit, true);
        }

        function get(limit, ignoreLoadingBar) {

            $scope.loading = true;

            if (!$scope.account || !$scope.account.part_of) {
                $scope.loading = false;
                return;
            }

            return account.getFeed($stateParams.id, { limit, types: getFilterTypes(), contacts: getFilterContacts(), canceler: 'feed-' + $stateParams.id }, ignoreLoadingBar).then(function (response) {

                $scope.loading = false;
                $scope.feed = response.data;

                if (!response.data || response.data.length < 10) {
                    $scope.everythingLoaded = true;
                }

                // Scroll to bottom of the feed when this is our first get
                if (!limit) {
                    $timeout(function () {

                        const feedInteraction = angular.element('#feed .interaction').last()[0] || null;

                        if (feedInteraction) {
                            utils.scrollIntoView(feedInteraction);
                        }
                    }, 100, false);
                }

                // Refresh meeting detail data since meeting could have been updated
                /*eslint no-loop-func: 0*/
                for (const key in $scope.feedDetails) {
                    const detail = $scope.feedDetails[key];

                    if (detail.type === 'meeting-live' || detail.type === 'meeting-phone' || detail.type === 'meeting') {

                        // Make sure it is still in the feed
                        for (let i = 0; i < response.data.length; ++i) {
                            const feedElement = response.data[i];

                            if (feedElement.id === detail.id && feedElement.type === detail.type) {
                                meeting.get(detail.id, true).then(function (meetingResponse) {

                                    detail.meeting = meetingResponse.data;
                                });
                            }
                        }
                    }
                }
            }, null, function (response) {

                $scope.loading = false;
                $scope.feed = response.data;

                if (!response.data || response.data.length < 10) {
                    $scope.everythingLoaded = true;
                }

                // Scroll to bottom of the feed when this is our first get
                if (!limit) {
                    $timeout(function () {

                        const feedInteraction = angular.element('#feed .interaction').last()[0] || null;

                        if (feedInteraction) {
                            utils.scrollIntoView(feedInteraction);
                        }
                    }, 100, false);
                }
            });
        }

        function init() {

            // Fill in customers filter now that we have the account
            const fetchedCustomerFilter = filterService.getAccountFeedCustomersFilter($scope.account.id) || $scope.account.customers.map(function (customer) {

                return customer.id;
            });

            /**
             * Fix for https://github.com/Salesflare/Server/issues/7145
             * There was an issue where we stored the wrong filter for an account with contacts from another account.
             * So we need to make sure that the customer filter only includes contacts that are actually in the account.
             *
             * Since we modify `$scope.feedFilterCustomers` later on with this list, it will trigger the `feedFilterCustomers` watch.
             * It will set the filter in local storage correctly for us.
             */
            let customerFilter = fetchedCustomerFilter.filter(function (customerId) {

                return $scope.account.customers.some(function (customer) {

                    return String(customer.id) === customerId;
                });
            });

            // If we end up with no customers and we made changes with our fix, default to all the customers of the account.
            if (customerFilter.length === 0 && customerFilter.length !== fetchedCustomerFilter.length) {
                customerFilter = $scope.account.customers.map(function (customer) {

                    return customer.id;
                });
            }

            customerFilter.forEach(function (customerId) {

                $scope.feedFilterCustomers[customerId] = true;
            });

            // We have to manually set excluded customers to false, otherwise the local storage will get overwritten later with undefined. Which would remove the filter by the next page visit
            const excludedCustomers = $scope.account.customers.filter(function (customer) {

                // Only return customers not currently listed in the feed customer filter
                return !$scope.feedFilterCustomers[customer.id];
            }).map(function (customer) {

                return customer.id;
            });

            excludedCustomers.forEach(function (customerId) {

                $scope.feedFilterCustomers[customerId] = $scope.feedFilterCustomers[customerId] ? true : false;
            });

            if ($scope.account.part_of) {
                $scope.loading = true;
                // Normally we would load the feed here.
                // But since we modified/set up the customer filter the `feedFilterCustomers` watch will trigger.
                // That watch will, in turn, trigger `get`.
                // If we would already fetch the feed here, we would fetch it twice for no reason.
            }
            else if (model.me.is_admin) {
                $scope.requestAccessText = 'Add yourself to the ' + $scope.account.name + ' team to see the timeline here.';
            }
            else if (!model.me.is_admin) {
                $scope.requestAccessText = 'To access this account timeline, ask your colleague(s) to add you to the team of the account.';
            }
        }

        function getFilterTypes() {

            // Turn feedFilterInteractionTypes map into array of strings
            // We do some conversion here since the view model updated but we need to be backwards compatible
            const filterOptionKeys = Object.keys($scope.feedFilterInteractionTypes);
            return filterOptionKeys.map(function (option) {

                if ($scope.feedFilterInteractionTypes[option]) {
                    return option;
                }

                // eslint-disable-next-line unicorn/no-useless-undefined
                return undefined;
            }).reduce(function (types, option) {

                if (!option) {
                    return types;
                }

                return [...types, ...(angular.isArray(typesMap[option]) ? typesMap[option] : [typesMap[option]])];
            }, []);
        }

        function getFilterContacts() {

            const filterContacts = Object.keys($scope.feedFilterCustomers);
            const remainingContacts = filterContacts.filter(function (contactId) {

                return $scope.feedFilterCustomers[contactId];
            });

            if ($scope.account.customers.length === remainingContacts.length) {
                return;
            }

            return remainingContacts;
        }
    }
})();
