import { OnInit, Component } from '@angular/core';
import { DialogContent } from '@shared/components/dialog/dialog.component';

import { ApiKeyModel as ApiKey } from '@shared/models/api-key.model';

export interface EditApiKeyContentData {
    apiKey: ApiKey
}

@Component({
    selector: 'sfx-api-key-settings-edit-api-key-content',
    template: `
        <span>
            <mat-form-field>
                <mat-label>Name</mat-label>
                <input required matInput [(ngModel)]="apiKeyName" #apiKeyNameField="ngModel">
                <mat-error *ngIf="apiKeyNameField.control.errors?.required">
                    Name is required
                </mat-error>
            </mat-form-field>
        </span>
    `,
    styles: ['.mat-form-field { width: 100% }']
})
export class EditApiKeyDialogContentComponent implements OnInit, DialogContent<EditApiKeyContentData> {

    public apiKey: ApiKey
    public apiKeyName: string;

    public valid = true;

    constructor(
    ) {}

    public ngOnInit(): void {}

    setInitialState(context: EditApiKeyContentData) {

        this.apiKey = context.apiKey || {};
        this.apiKeyName = this.apiKey.name || '';
    }

    getCurrentState(): EditApiKeyContentData {

        return { apiKey: { ...this.apiKey, name: this.apiKeyName } };
    }
}
