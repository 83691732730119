<sfx-toolbar enableDivider>
    Support
</sfx-toolbar>
<sfx-content>
    <sfx-header disableDividers disableBackground>
        Knowledge base
    </sfx-header>
    <sfx-section disableContentPadding>
        <div class="accent">
            <span>Have a question about the product? Read advice and answers from the Salesflare team.</span>
            <p>
                <a class="icon-link" href="https://howto.salesflare.com" target="_blank" rel="noopener"> <mat-icon fontSet="salesflare" fontIcon="sf-icon-description" class="icon-18 button"></mat-icon>Check the knowledge base</a>
            </p>
        </div>
    </sfx-section>
    <sfx-header disableDividers disableBackground>
        API Docs
    </sfx-header>
    <sfx-section disableContentPadding>
        <div class="accent">
            <span>Developing on top of Salesflare? Read our API Docs.</span>
            <p>
                <a class="icon-link" href="https://api.salesflare.com/docs" target="_blank" rel="noopener"> <mat-icon fontSet="salesflare" fontIcon="sf-icon-settings" class="icon-18 button"></mat-icon>Check the API Docs</a>
            </p>
        </div>
    </sfx-section>
    <sfx-header disableDividers disableBackground>
        Contact Us
    </sfx-header>
    <sfx-section disableContentPadding>
        <div class="accent">
            <span>Can't find what you're looking for?</span>
            <p>
                <a class="icon-link" id="intercom-chat-button" href="mailto: support@salesflare.com"> <mat-icon fontSet="salesflare" fontIcon="sf-icon-support" class="icon-18 button"></mat-icon>Have a chat</a>
            </p>
            <p>
                <a class="icon-link" href="https://salesfla.re/talk-to-support" target="_blank" rel="noopener"> <mat-icon fontSet="salesflare" fontIcon="sf-icon-conf-call" class="icon-18 button"></mat-icon>Book a video call</a>
            </p>
            <p>
                <a class="icon-link" (click)="mailTo('support@salesflare.com', $event)"> <mat-icon fontSet="salesflare" fontIcon="sf-icon-email" class="icon-18 button"></mat-icon>Email support@salesflare.com</a>
            </p>
        </div>
    </sfx-section>
</sfx-content>