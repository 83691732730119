(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('AccountContactsController', AccountContactsController);

    function AccountContactsController($rootScope, $scope, $stateParams, $mdDialog, $mdMedia, email, events, helperFunctionsService) {

        const allContacts = $stateParams.contacts;
        $scope.contacts = allContacts;


        $scope.mailto = email.mailto;
        $scope.callPerson = (person, $event) => {

            return helperFunctionsService.callPerson(person, $event).then(function () {

                return $rootScope.$broadcast(events.account.updated);
            });
        };

        $scope.$watch('search', function (newValue, oldValue) {

            if (newValue !== oldValue) {
                if (!newValue) {
                    $scope.contacts = allContacts;
                    return;
                }

                $scope.contacts = allContacts.filter((contact) => {

                    return contact.name.toLowerCase().includes(newValue.toLowerCase())
                        || contact.email.toLowerCase().includes(newValue.toLowerCase());
                });
            }
        });

        $scope.back = function () {

            if ($mdMedia('gt-sm')) {
                return $mdDialog.hide();
            }

            return $rootScope.back();
        };
    }
})();
