(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('utils', utilsService);

    function utilsService($rootScope, $document, $mdDialog, $mdToast, $q, $interval, $timeout, $window, $location, $filter, $exceptionHandler, config) {

        const self = this;

        this.dataURIToBlob = function (dataURI) {
            // Convert base64 to raw binary data held in a string
            // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
            const byteString = atob(dataURI.split(',')[1]);

            // Separate out the mime component
            const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            // Write the bytes of the string to an ArrayBuffer
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; ++i) {
                ia[i] = byteString.codePointAt(i);
            }

            // Write the ArrayBuffer to a blob
            const blob = new Blob([ab], { type: mimeString });
            return blob;
        };

        /**
         * Strips all `undefined` and `''` key value properties
         * Does this deep aka `{ a: { b: undefined } }` => `{ a: {} }`
         * NULL values are kept since Tri-State boolean values can get this value
         *
         * @param {Object} object
         */
        this.stripNil = function (object) {

            self.strip(object, function (x) {

                return angular.isUndefined(x) || x === '';
            });
        };

        /**
         * Strips `undefined`, `''` and `null` values
         *
         * @param {Object} object
         */
        this.stripNilAndNull = function (object) {

            self.strip(object, function (x) {

                return angular.isUndefined(x) || x === '' || x === null;
            });
        };

        /**
         * Strips empty arrays
         *
         * @param {Object} object
         */
        this.stripEmptyArray = function (object) {

            self.strip(object, function (x) {

                return angular.isArray(x) && x.length === 0;
            });
        };

        /**
         *
         * @param {Object} object
         * @param {function(String):Boolean} testFunction
         */
        this.strip = function (object, testFunction) {

            for (const key in object) {
                if (Object.prototype.hasOwnProperty.call(object, key)) {
                    if (testFunction(object[key])) {
                        delete object[key];
                    }
                    else if ($.isPlainObject(object[key])) {
                        this.strip(object[key], testFunction);
                    }
                }
            }
        };


        /**
         * This utility function should be used when we need to treat a UTC date as local time/dates without a time portion.
         * For example when we receive "2017-03-22T00:00:00.000Z" from our backend and we would call new Date() with this ISO-string we could get "Wed Mar 21 2017 23:00:00 GMT-0100" if we are in a UTC-1 timezone
         * We offset the date object with the local timezone (for dates where the time portion is not relevant) to prevent any date jumps when binding the date to a date picker.
         *
         * @param {String} UTCDateString
         * @returns {Object} LocalDateObject
         */
        this.UTCDateStringToLocalDateObject = function (UTCDateString) {

            if (!UTCDateString) {
                return null;
            }

            // If the date is already an object, return that object. This function isn't meant for transforming objects to the correct local date
            if (!angular.isString(UTCDateString) && !(UTCDateString instanceof String)) {
                return UTCDateString;
            }

            const d = new Date(UTCDateString);

            if (Number.isNaN(d.getTime())) {
                return null;
            }

            d.setTime(d.getTime() + (d.getTimezoneOffset() * 60000));
            return d;
        };

        /**
         * This function negates the effect of the function above.
         * Call this function before sending the date back to the server to ensure we always send the correct date at UTC midnight.
         *
         * @param {Date | null} localDate
         * @returns {Date | null}
         */
        this.localDateObjectToUTCDateObject = function (localDate) {

            if (angular.isDate(localDate)) {
                if (Number.isNaN(localDate.getTime())) {
                    return null;
                }

                const d = angular.copy(localDate);
                d.setTime(d.getTime() - (d.getTimezoneOffset() * 60000));
                return d;
            }

            return null;
        };

        this.getDayOrdinal = function (day) {

            if (day > 3 && day < 21) {
                return 'th';
            }

            switch (day % 10) {
                case 1:  return 'st';
                case 2:  return 'nd';
                case 3:  return 'rd';
                default: return 'th';
            }
        };

        this.getStartOfLocalDay = function (localDate) {

            if (angular.isDate(localDate)) {
                if (Number.isNaN(localDate.getTime())) {
                    return null;
                }

                const d = angular.copy(localDate);
                d.setHours(0, 0, 0, 0);
                return d;
            }

            return null;
        };

        this.getEndOfLocalDay = function (localDate) {

            if (angular.isDate(localDate)) {
                if (Number.isNaN(localDate.getTime())) {
                    return null;
                }

                const d = angular.copy(localDate);
                d.setHours(23, 59, 59, 999);
                return d;
            }

            return null;
        };

        this.getTodayAtMidnight = function () {

            const date = new Date();
            date.setHours(0, 0, 0, 0);

            return date;
        };

        this.isValidDate = function (date) {

            return date && angular.isDate(date) && !Number.isNaN(date);
        };

        /**
         *
         * @param {Date} date
         * @returns {Boolean}
         */
        this.isToday = function (date) {

            return this.isSameDay(new Date(), date);
        };

        /**
         *
         * @param {Date} date1
         * @param {Date} date2
         * @returns {Boolean}
         */
        this.isSameHour = function (date1, date2) {

            return date1.getHours() === date2.getHours();
        };

        /**
         *
         * @param {Date} date1
         * @param {Date} date2
         * @returns {Boolean}
         */
        this.isSameMinute = function (date1, date2) {

            return date1.getMinutes() === date2.getMinutes();
        };

        /**
         *
         * @param {Date} dt1
         * @param {Date} dt2
         * @returns {Boolean}
         */
        this.isSameDay = function (dt1, dt2) {

            const date1 = new Date(dt1).setHours(0, 0, 0, 0);
            const date2 = new Date(dt2).setHours(0, 0, 0, 0);

            return (date1 === date2);
        };

        /**
         *
         * @param {Date} dt1
         * @param {Date} dt2
         * @returns {Boolean}
         */
        this.isSameMonth = function (dt1, dt2) {

            dt1 = new Date(dt1);
            dt2 = new Date(dt2);

            return (dt1.getFullYear() === dt2.getFullYear() && dt1.getMonth() === dt2.getMonth());
        };

        /* The utility date difference functions below are used to calculate the calculated_value of an opportunity.
         * This calculation also happens server-side so if you make any changes to these utility functions you might also want to make these changes on the server.
         * Just to make sure the calculated_value calculated client-side always equals the one calculated on the server-side. */
        this.getDayDifference = function (firstDate, secondDate) {

            const oneDay = 24 * 60 * 60 * 1000; // Hours*minutes*seconds*milliseconds
            return Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
        };

        this.getWeekDifference = function (firstDate, secondDate) {

            const oneWeek = 7 * 24 * 60 * 60 * 1000; // Hours*minutes*seconds*milliseconds*days in a week
            return Math.ceil(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneWeek)));
        };

        this.getMonthDifference = function (firstDate, secondDate) {

            const fullMonthDifference = secondDate.getMonth() -  firstDate.getMonth() + (12 * (secondDate.getFullYear() - firstDate.getFullYear()));

            if (secondDate.getDate() > firstDate.getDate()) {
                return fullMonthDifference + 1;
            }

            return fullMonthDifference;
        };

        this.getYearDifference = function (firstDate, secondDate) {

            const fullYearDifference = secondDate.getFullYear() - firstDate.getFullYear();

            if ((secondDate.getMonth() > firstDate.getMonth()) || (secondDate.getMonth() === firstDate.getMonth() && secondDate.getDate() > firstDate.getDate())) {
                return fullYearDifference + 1;
            }

            return fullYearDifference;
        };

        this.halfHoursOfTheDay = [
            [0, 0],
            [0, 30],
            [1, 0],
            [1, 30],
            [2, 0],
            [2, 30],
            [3, 0],
            [3, 30],
            [4, 0],
            [4, 30],
            [5, 0],
            [5, 30],
            [6, 0],
            [6, 30],
            [7, 0],
            [7, 30],
            [8, 0],
            [8, 30],
            [9, 0],
            [9, 30],
            [10, 0],
            [10, 30],
            [11, 0],
            [11, 30],
            [12, 0],
            [12, 30],
            [13, 0],
            [13, 30],
            [14, 0],
            [14, 30],
            [15, 0],
            [15, 30],
            [16, 0],
            [16, 30],
            [17, 0],
            [17, 30],
            [18, 0],
            [18, 30],
            [19, 0],
            [19, 30],
            [20, 0],
            [20, 30],
            [21, 0],
            [21, 30],
            [22, 0],
            [22, 30],
            [23, 0],
            [23, 30]
        ];

        /**
         * @typedef {Object} dayOfTheWeek
         * @property {Number} index
         * @property {String} name
         * @typedef {Array.<dayOfTheWeek>} daysOfTheWeek
         */
        const daysOfTheWeek = [
            { index: 0, name: 'Sunday' }, // Sunday is only 0 because JS says so and this makes it easier to adapt to the regional setting
            { index: 1, name: 'Monday' },
            { index: 2, name: 'Tuesday' },
            { index: 3, name: 'Wednesday' },
            { index: 4, name: 'Thursday' },
            { index: 5, name: 'Friday' },
            { index: 6, name: 'Saturday' }
        ];

        /**
         *
         * @param {Number} startDay 0 = Sunday, 1 = Monday, 6 = Saturday, taken from regional settings
         * @returns {daysOfTheWeek}
         */
        this.getDaysOfTheWeekStartingOn = function (startDay) {

            const modifiedWeek = angular.copy(daysOfTheWeek);
            for (let i = 0; i < startDay; ++i) {
                modifiedWeek.push(modifiedWeek.shift());
            }

            return modifiedWeek;
        };

        this.formatTimestamp = function (timestamp) {

            const date = new Date(timestamp);

            const hours = ('0' + date.getHours()).slice(-2);
            const minutes = ('0' + date.getMinutes()).slice(-2);

            return (hours + ':' + minutes + ':00');
        };

        // http://stackoverflow.com/a/16321280/2339622
        this.setLongTimeout = function (callback, delay) {

            // If we have to wait more than max time, need to recursively call this function again
            if (delay > 2147483647) {
                // Now wait until the max wait time passes then call this function again with
                // requested wait - max wait we just did, make sure and pass callback
                return $timeout(function () {

                    return self.setLongTimeout(callback, (delay - 2147483647));
                }, 2147483647);
            }
            else {
                return $timeout(callback, delay);
            }
        };

        this.findById = function (items, id) {

            return items.find(function (item) {

                return item.id === id;
            });
        };

        // Taken from https://github.com/hapijs/hoek
        this.unique = function (array, key) {

            const index = {};
            const result = [];

            for (const element of array) {
                const id = (key ? element[key] : element);

                if (index[id] !== true) {
                    result.push(element);

                    index[id] = true;
                }
            }

            return result;
        };

        this.subtract = function (arr1, arr2) {

            return arr1.filter(function (o1) {

                for (const o2 of arr2) {

                    if (angular.equals(o1, o2)) {
                        return false;
                    }
                }

                return true;
            });
        };

        let showing; // Is a promise
        this.showOfflineDialog = function () {

            if (!showing) {
                showing = $mdDialog.show({
                    parent: $document.body,
                    clickOutsideToClose: false,
                    escapeToClose: false,
                    template:
                    '<md-dialog id="onlineOffline">' +
                    '   <md-dialog-content class="md-dialog-content">' +
                    '       <p>Trying to regain connection to the server...</p>' +
                    '       <md-progress-circular md-diameter="25" md-mode="indeterminate"></md-progress-circular>' +
                    '       <md-button ng-click="retry()">Retry</md-button>' +
                    '   </md-dialog-content>' +
                    '</md-dialog>',
                    controller: 'OnlineOfflineDialogController'
                });
            }

            return showing;
        };

        this.forceFocus = function (element) {

            return $timeout(function () {

                if (angular.element(element).is(':visible')) {
                    return element.focus();
                }
                else {
                    return self.forceFocus(element);
                }
            }, 100);
        };

        this.hideOfflineDialog = function () {

            return $mdDialog.hide();
        };

        this.nl2br = function (text) {

            if (text) {
                return text.replace(/\n|\r\n|\n\r|\r/g, '<br>');
            }

            return text;
        };

        /**
         * Deals with popups
         *
         * This will open a new window with the `_popup` target or the one provided with the url
         * On mobile it will use the `inappbrowser` and you get back the query params
         * On mobile with a target of `_system`, `inappbrowser` can't be used so the user will come back manually so you won't get back the query params
         * You probably want to reload state at this point
         * On desktop we basically poll until done and then give back the query params
         *
         * @param {String} url
         * @param {String} providedTarget
         * @returns {Promise}
         */
        this.popup = function popup(url, providedTarget) {

            const target = providedTarget || '_popup';

            return $q(function (resolve) {

                // This is one of the very few cases where we do not set the `noopener` since it would prevent returning the window object we use
                const win = $window.open(url, target);

                // Win is not there when using Webview in Outlook
                // .focus is not always there on mobile
                if (win && win.focus) {
                    win.focus();
                }

                // If target is _system on mobile the eventListener won't work
                if ($window.isMobile && target !== '_system') {
                    win.addEventListener('loadstart', function (data) {

                        if (data.url.includes('closeme')) {
                            win.close();

                            const queryParams = new URLSearchParams(new URL(data.url).search);

                            return resolve(queryParams);
                        }
                    });

                    return win.addEventListener('exit', function () {

                        win.close();

                        return resolve();
                    });
                }
                else if ($window.isMobile && target === '_system') {
                    /**
                     * On mobile the app is registered to handle 'salesflare://' urls
                     * so on mobile we redirect to that and we end up in here
                     * the standard way of going to closeme.html doesn't really work
                     * since the page is not allowed to self redirect
                     *
                     * @param {String} urlString
                     * @returns {void}
                     */
                    $window.handleOpenURL = function (urlString) {

                        const queryParams = new URLSearchParams(new URL(urlString).searchParams);

                        return resolve(queryParams);
                    };
                }
                else if (win) {
                    let queryParams;

                    // eslint-disable-next-line func-style
                    const storageListener = function storageListener(event) {

                        if (event.key === 'closeme' && event.newValue) {
                            // It's important that we fetch the query params before calling win.close() since that may cause losing our reference to the window (I'm looking at you IE...)
                            queryParams = new URLSearchParams(event.newValue);
                            win.close();
                        }
                    };

                    $window.addEventListener('storage', storageListener);

                    const interval = $interval(function () {

                        // Try catch to test if we can access the href
                        // if not we just keep polling
                        try {
                            const loc = angular.copy(win.location);

                            if (loc.href.includes('closeme')) {
                                // It's important that we fetch the query params before calling win.close() since that may cause losing our reference to the window (I'm looking at you IE...)
                                queryParams = new URLSearchParams(loc.search);
                                win.close();
                            }
                        }
                        catch {
                            // Not the same origin so we wait for win to come back to closeme or closes
                        }

                        if (win.closed) {
                            $interval.cancel(interval);
                            $window.removeEventListener('storage', storageListener);
                            store.remove('closeme');
                            return resolve(queryParams);
                        }
                    }, 100);
                }
                else {
                    const storageListenerInterval = $interval(function () {

                        if (store.get('closeme')) {
                            $interval.cancel(storageListenerInterval);
                            const queryParams = new URLSearchParams(store.get('closeme'));
                            store.remove('closeme');

                            const message = {
                                function: 'closePopup'
                            };

                            $window.external.notify(angular.toJson(message));
                            return resolve(queryParams);
                        }
                    }, 100);
                }
            });
        };

        /**
         * Try to keep `action` short
         *
         * If `action` is provided the promise will return with the value of `action` if it was clicked, otherwise `undefined`
         *
         * On state change, will reject the promise and hide the toast
         *
         * @param {String} [message]
         * @param {String} [action]
         * @returns {void}
         */
        this.showErrorToast = function (message, action) {

            return showToast('error', message || 'Oops! Something went wrong. Please try again or contact support.', action);
        };

        this.showSuccessToast = function (message, action, delay) {

            return showToast('success', message || 'Success!', action, delay);
        };

        this.showInfoToast = function (message, action, delay) {

            return showToast('info', message, action, delay);
        };

        function showToast(type, message, action, delay) {

            let hideDelay;

            if (angular.isDefined(delay)) {
                hideDelay = delay;
            }
            else if (type === 'error') {
                hideDelay = 5000;
            }
            else if (type === 'info') {
                hideDelay = 20000;
            }
            else {
                hideDelay = 3000; // Default in angular material
            }

            return $mdToast.show({
                controller: 'ToastController',
                templateUrl: 'partials/toast.html',
                locals: {
                    type,
                    message,
                    action
                },
                position: 'bottom right',
                hideDelay
            });
        }

        this.hideToast = (reason) => {

            const promiseResponse = reason ? reason : 'NEW TOAST';
            return $mdToast.hide(promiseResponse);
        };

        /**
         * This will update the search part of the url
         * Will not cause a reload of the state or event to trigger
         *
         * @param {{ search: String }} searchObject
         * @returns {void}
         */
        this.setSearchInUrl = function (searchObject) {

            return $location.search('search', searchObject.search);
        };

        this.formatDate = function (date) {

            return $filter('date')(date, 'EEEE, MMMM d, yyyy');
        };

        /**
         * Iframe friendly scrollIntoView
         * It is possible that when loaded in an Iframe we also scroll the parent wen doing `element.scrollIntoView`
         * Which is not something we want.
         * This function looks at the offsetParent recursively to see where it needs to scroll.
         *
         * This is based on https://github.com/mozilla/pdf.js/blob/70cad2f053de7cdc8fbb6c0c17e6589d842cba75/web/ui_utils.js#L107
         *
         * @param {HTMLElement} element
         */
        this.scrollIntoView = function scrollIntoView(element) {

            let parent = element.offsetParent;
            if (!parent) {
                $exceptionHandler('offsetParent is not set -- cannot scroll');
                return;
            }

            let offsetY = element.offsetTop;
            while (parent.clientHeight === parent.scrollHeight) {
                // eslint-disable-next-line no-underscore-dangle
                if (parent.dataset._scaleY) {
                    // eslint-disable-next-line no-underscore-dangle
                    offsetY /= parent.dataset._scaleY;
                }

                offsetY += parent.offsetTop;

                parent = parent.offsetParent;

                if (!parent) {
                    return; // No need to scroll or your layout is doing something weird (might be some overflow stuff).
                }
            }

            parent.scrollTop = offsetY;
        };

        /**
         * @param {String} planName
         * @param {'annually'|'monthly'} billingFrequency
         * @param {'USD'|'EUR'} currency
         * @returns {String}
         */
        this.getStripePlanId = function (planName, billingFrequency, currency) {

            return config.stripe.plans[(billingFrequency + '_' + planName.toUpperCase() + '_' + currency.toUpperCase())];
        };

        /**
         * @param {Number} planId
         * @returns {String}
         */
        this.getPlanNameBySalesflarePlanId = function (planId) {

            if (planId === 4) {
                return 'pro';
            }

            if (planId === 5) {
                return 'enterprise';
            }

            return 'growth'; // Fallback
        };

        this.animations = {
            fadeOut: function (elem, ms, onComplete) {

                if (ms) {
                    // eslint-disable-next-line func-style
                    const onTransitioned = function onTransitioned() {

                        elem.removeEventListener('transitionend', onTransitioned, false);

                        elem.style.display = 'none';

                        return onComplete ? onComplete() : undefined;
                    };

                    elem.addEventListener('transitionend', () => {

                        $rootScope.$applyAsync(onTransitioned);
                    }, false);

                    elem.style.transition = 'opacity ' + ms + 'ms';
                    elem.style.opacity = '0';
                }
                else {
                    elem.style.opacity = '0';
                    return onComplete ? onComplete() : undefined;
                }
            },
            fadeIn: function (elem, ms, onComplete) {

                elem.style.opacity = 0;
                elem.style.display = 'block';

                if (ms) {
                    let opacity = 0;
                    const timer = $interval(function () {

                        opacity += 50 / ms;
                        if (opacity >= 1) {
                            $interval.cancel(timer);
                            opacity = 1;
                            elem.style.opacity = opacity;
                            return onComplete ? onComplete() : undefined;
                        }
                        else {
                            elem.style.opacity = opacity;
                        }
                    }, 50);
                }
                else {
                    elem.style.opacity = 1;

                    return onComplete ? onComplete() : undefined;
                }
            }
        };

        /**
         * Returns a string to display the amount of entities shown
         *
         * @param {Object} entityCountsObject
         * @param {Number} entityCountsObject.selectedCount The selected amount if any
         * @param {Number} entityCountsObject.viewCurrentCount The current amount of entities after searching/filtering/...
         * @param {Number} entityCountsObject.viewTotalCount The total amount of available entities for that view
         * @param {String} entityCountsObject.entity
         * @param {String} entityCountsObject.entity_singular will be used if the displayed value is 1
         * @param {String} entityCountsObject.option Can be '', 'search' or 'filter'
         * @returns {String}
         */
        this.getEntityCountString = function getEntityCountString(entityCountsObject) {

            const option = entityCountsObject.option;
            const selectedCount = entityCountsObject.selectedCount;
            const currentCount = entityCountsObject.viewCurrentCount;
            const totalCount = entityCountsObject.viewTotalCount;
            const entity = entityCountsObject.entity;
            const entitySingular = entityCountsObject.entity_singular;
            const sortString = entityCountsObject.sortString ? ' by ' + entityCountsObject.sortString : '';

            if (selectedCount > 0) {
                // Selected entities always get priority
                if (option !== '') {
                    if (option === 'search') {
                        return '' + selectedCount + ' of ' + currentCount + ' ' + getEntityString(currentCount, entity, entitySingular) + ' selected';
                    }
                    else if (option === 'filter') {
                        return '' + selectedCount + ' of ' + currentCount + ' ' + getEntityString(currentCount, entity, entitySingular) + ' selected';
                    }
                    else {
                        throw new Error('Invalid parameters for entity count string generation');
                    }
                }

                return '' + selectedCount + ' of ' + totalCount + ' ' + getEntityString(totalCount, entity, entitySingular) + ' selected';
            }
            else if (option !== '') {
                return '' + currentCount + ' of ' + totalCount + ' ' + getEntityString(totalCount, entity, entitySingular) + sortString;
            }
            else if (currentCount > 0) {
                return '' + currentCount + ' ' + getEntityString(currentCount, entity, entitySingular) + sortString;
            }
            else {
                return '' + totalCount + ' ' + getEntityString(totalCount, entity, entitySingular) + sortString;
            }
        };

        /**
         * @returns {Date}
         */
        this.getNextHalfHourDateTime = function () {

            // Get next half hour date time from now
            let minutes = new Date().getMinutes();
            let hours = new Date().getHours();
            if (minutes < 30) {
                minutes = 30;
            }
            else {
                minutes = 0;
                hours++;
            }

            return new Date(new Date().setHours(hours, minutes, 0, 0));
        };

        /**
         *
         * @param {Number} count
         * @param {String} plural
         * @param {String} [singular]
         * @returns {String}
         */
        function getEntityString(count, plural, singular) {

            if (count > 1 || count === 0) {
                return plural;
            }
            else if (singular) {
                return singular;
            }

            return plural;
        }

        /**
         * Returns a contact that can be used for merging
         *
         * @param {Object} contact
         * @returns {Object}
         */
        this.getMergableContact = function (contact) {

            let account = {
                name: null,
                website: null
            };

            if (contact.account) {
                account = contact.account;
            }

            // Prepare country and city fields
            if (contact.addresses && contact.addresses.length > 0) {
                contact.country = contact.addresses[0].country;
                contact.city = contact.addresses[0].city;
            }
            else if (account.addresses && account.addresses.length > 0) {
                contact.country = account.addresses[0].country;
                contact.city = account.addresses[0].city;
            }

            const  mergeObject = {
                first_name: contact.firstname,
                last_name: contact.lastname,
                prefix: contact.prefix,
                suffix: contact.suffix,
                middle_name: contact.middle_name,
                name: contact.name,
                email: contact.email,
                account_name: account.name,
                website: account.website,
                phone_number: contact.phone_number,
                country: contact.country,
                city: contact.city,
                files: contact.files && contact.files.map(function (file) {

                    return '<a href="' + file.download_url + '" target="_blank" rel="noopener noreferrer" style="color: #6d7684;">' + file.name + '</a>';
                })
            };

            if (account && account.custom) {
                setCustomMergeFieldValue(mergeObject, account.custom, 'account');
            }

            setCustomMergeFieldValue(mergeObject, contact.custom, 'contact');

            return mergeObject;
        };

        this.getReadableTimeUntilTrialEnds = function (expDate) {

            const diffDays = Math.abs((Date.now() - new Date(expDate).getTime()) / (24 * 60 * 60 * 1000));
            let timeUntilTrialEnd = '';
            const expDateInFuture = (new Date(expDate) > new Date());

            if (diffDays < 1) {
                let diffHours = Math.abs((Date.now() - new Date(expDate).getTime()) / (60 * 60 * 1000));
                diffHours = Math.round(diffHours);

                if (diffHours === 1) {
                    timeUntilTrialEnd = expDateInFuture ? 'in 1 hour.' : '1 hour ago.';
                }
                else if (diffHours === 0) {
                    timeUntilTrialEnd = expDateInFuture ? ' soon!' : ' just now!';
                }
                else {
                    timeUntilTrialEnd = expDateInFuture ? ('in ' + diffHours + ' hours.') : (diffHours + ' hours ago.');
                }
            }
            else if (diffDays === 1) {
                timeUntilTrialEnd = expDateInFuture ? 'in 1 day.' : '1 day ago.';
            }
            else {
                timeUntilTrialEnd = expDateInFuture ? ('in ' + Math.ceil(diffDays) + ' days.') : (Math.ceil(diffDays) + ' days ago.');
            }

            return timeUntilTrialEnd;
        };

        /**
         * From @see https://stackoverflow.com/a/57401891/2339622
         *
         * @param {String} color hex color
         * @param {Number} amount positive number means lighter
         * @returns {String}
         */
        this.adjustColor = function (color, amount) {

            return '#' + color.replace(/^#/, '').replace(/../g, function (c) {

                return ('0' + Math.min(255, Math.max(0, Number.parseInt(c, 16) + amount)).toString(16)).slice(-2);
            });
        };

        function setCustomMergeFieldValue(mergeObject, customObject, itemClass) {

            for (const key in customObject) {
                if (Object.prototype.hasOwnProperty.call(customObject, key)) {
                    const customFieldValue = customObject[key];
                    const mergeObjectKey = itemClass + '_' + key;

                    if (angular.isUndefined(customFieldValue) || customFieldValue === null) {
                        mergeObject[mergeObjectKey] = null;
                    }
                    else if (angular.isArray(customFieldValue)) {
                        mergeObject[mergeObjectKey] = customObject[key].map(mapCustomFieldArrayValueMapFunction).join(', ').replace(/, ([^,]*)$/, ' & $1');
                    }
                    else if (angular.isObject(customFieldValue)) {
                        mergeObject[mergeObjectKey] = customObject[key].name;
                    }
                    else {
                        mergeObject[mergeObjectKey] = customObject[key];
                    }
                }
            }
        }

        function mapCustomFieldArrayValueMapFunction(ac) {

            if (ac.download_url) {
                return '<a href="' + ac.download_url + '" target="_blank" rel="noopener noreferrer" style="color: #6d7684;">' + ac.name + '</a>';
            }

            return ac.name;
        }

        this.shallowMergeIfNull = function (destination, source) {

            const keys = Object.keys(source);
            keys.forEach(function (key) {

                if (destination[key] === null) {
                    destination[key] = source[key];
                }
            });
        };

        this.imageUrlToDataUrl = async (url) => {

            const data = await fetch(url);
            const blob = await data.blob();

            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const base64data = reader.result;
                    resolve(base64data);
                };
            });
        };

        this.getUsernameFromLinkedInUrl = (url) => {

            const regex = /https?:\/\/([A-Za-z_]{0,3}\.)?linkedin\.com\/(((sales\/)?(in|pub|people|company|companies|organization|edu|school|groups)\/)|(profile\/view\?id=[a-zA-Z]))([^ "/\n]+)/ig; //eslint-disable-line unicorn/no-unsafe-regex
            const result = regex.exec(url);

            return result && result[result.length - 1];
        };

        /**
         * Relatively simple regex to validate an email with
         * There are probably some valid email address that won't match this regex but it will match most common cases
         *
         * Taken from Chromium: https://stackoverflow.com/a/46181
         *
         * @param {String} email
         * @returns {Boolean}
         */
        this.validateEmail = (email) => {

            // eslint-disable-next-line unicorn/no-unsafe-regex
            return !!/^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(email);
        };

        this.isElementInViewport = (element) => {

            const rect = element.getBoundingClientRect();

            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= ($window.innerHeight || $document[0].documentElement.clientHeight) &&
                rect.right <= ($window.innerWidth || $document[0].documentElement.clientWidth)
            );
        };

        this.getAccountCreationConfirmDialog = (accountHasOwnDomain, accountHasNonCompanyDomain) => {

            let dialogContent;

            if (accountHasNonCompanyDomain) {
                dialogContent = 'If you create an account with a domain of an email provider, it will start connecting a lot of unrelated information.';
            }
            else if (accountHasOwnDomain) {
                dialogContent = 'If you create an account for your own company, it will be shown next to every email in the email sidebar.';
            }

            return $mdDialog.confirm({ multiple: true })
                .clickOutsideToClose(true)
                .escapeToClose(true)
                .title('Are you sure?')
                .htmlContent(dialogContent)
                .ok('Yes')
                .cancel('No');
        };

        /**
         * Prepares an entity so it can display all custom fields in the edit form
         *
         * @param {Object} entity
         * @param {Array.<Object>} customFields
         */
        this.setCustomFieldsOnEntity = function (entity, customFields) {

            customFields.forEach(function (customField) {

                let customFieldValue = customField.predefined_customfield ? entity[customField.api_field] : entity.custom[customField.api_field];
                let foundCustomField;

                switch (customField.type.type) {
                    case 'tags':
                    case 'accounts':
                    case 'contacts':
                    case 'users':
                        // It needs to be an array otherwise md-chips starts failing
                        customFieldValue = customFieldValue || [];
                        break;
                    case 'boolean':
                    case 'tri_boolean':
                        if (angular.isUndefined(customFieldValue)) {
                            customFieldValue = customField.default_boolean_value;
                        }

                        break;
                    case 'select':
                    case 'multiselect':

                        // Allow unsetting the custom field
                        if (customField.type.type === 'select' && !customField.required) {
                            customField.options = [{ name: '' }, ...customField.options];
                        }

                        foundCustomField = customFields.find(function (cf) {

                            return cf.id === customField.id;
                        });

                        // Enrich the custom field value with the full object of the option
                        if (foundCustomField && foundCustomField.options) {
                            for (let i = 0; i < foundCustomField.options.length; ++i) {
                                const opt = foundCustomField.options[i];

                                if (!customFieldValue && opt.id) {
                                    continue;
                                }

                                if ((!opt.id && !customFieldValue) || (customFieldValue.id === opt.id)) {
                                    customFieldValue = opt;
                                    break;
                                }
                            }
                        }

                        break;
                    case 'date':
                        customField.min_date = self.UTCDateStringToLocalDateObject(customField.min_date);
                        customField.max_date = self.UTCDateStringToLocalDateObject(customField.max_date);
                        customFieldValue = self.UTCDateStringToLocalDateObject(customFieldValue);
                        break;
                }

                // Set the resulting value on the entity
                if (customField.predefined_customfield) {
                    entity[customField.api_field] = customFieldValue;
                }
                else if (entity.custom && Object.keys(entity.custom).length > 0) {
                    entity.custom[customField.api_field] = customFieldValue;
                }
            });
        };
    }
})();
