(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('accounts', accountsService);

    function accountsService(sfHttp, config, filterService) {

        this.get = function (search, filters, limit, offset, orderBy, downloadCSV, downloadExcel) {

            const url = config.apiUrl + 'accounts';
            const request = {
                cache: true,
                downloadCSV,
                downloadExcel,
                params: (filters && filterService.sanitizeAccountFilterForHttp(angular.copy(filters))) || {}
            };

            if (downloadCSV || downloadExcel) {
                request.params.details = true;
            }
            else {
                request.params.details = false;
            }

            // Use if's to not override with undefined/null/false
            if (search) {
                request.cache = false;
                request.ignoreLoadingBar = true;
                request.params.search = search === '' ? undefined : search;
            }

            if (limit) {
                request.params.limit = limit;
            }

            if (offset) {
                request.params.offset = offset;
            }

            if (orderBy) {
                request.params.order_by = orderBy;
            }

            return sfHttp.get(url, request);
        };

        this.getRelated = function (contacts, domain, socialProfile) {

            const url = config.apiUrl + 'accounts/related';

            const request = {
                ignoreLoadingBar: true,
                params: {}
            };

            if (contacts) {
                request.params.contacts = angular.toJson(contacts);
            }

            if (socialProfile) {
                request.params.social_profile_url = socialProfile;
            }

            if (domain) {
                request.params.domain = domain;
            }

            return sfHttp.get(url, request);
        };

        this.update = function (accounts) {

            return sfHttp.put(config.apiUrl + 'accounts', accounts);
        };

        this.getWalkthroughAccountSuggestion = function () {

            const url = config.apiUrl + 'accountsuggestions';

            return sfHttp.get(url);
        };

        /**
         * New filter call
         * TODO: make a unified sanitize filter for Http
         *
         * @param {Object} options
         * @param {String} [options.search]
         * @param {Array.<Object>} options.filterRules
         * @param {Number} [options.limit]
         * @param {Number} [options.offset]
         * @param {String} [options.orderBy]
         * @param {Boolean} [options.downloadCSV=false]
         * @param {Boolean} [options.downloadExcel=false]
         * @param {Boolean} [options.returnCountOnly=false]
         * @param {Boolean} [options.includeCount=false]
         * @returns {Promise}
         */
        this.filterGet = function (options) {

            const filterUrl = config.apiUrl + 'accounts';

            const request = {
                downloadCSV: options.downloadCSV,
                downloadExcel: options.downloadExcel,
                params: {
                    q: {
                        condition: 'AND',
                        rules: options.filterRules
                    }
                },
                noToast: true,
                noToastForStatusCode: [402, 422]
            };

            if (options.exportFields) {
                request.params.export = options.exportFields;
            }

            if (options.search) {
                request.cache = false;
                request.ignoreLoadingBar = true;
                request.params.search = options.search === '' ? undefined : options.search;
            }

            if (options.downloadCSV || options.downloadExcel) {
                request.params.details = true;
            }
            else {
                request.params.details = false;
            }

            if (options.limit) {
                request.params.limit = options.limit;
            }

            if (options.offset) {
                request.params.offset = options.offset;
            }

            if (options.orderBy) {
                request.params.order_by = options.orderBy;
            }

            if (options.returnCountOnly) {
                return sfHttp.head(filterUrl, request);
            }

            if (options.includeCount) {
                request.headers = { 'x-result-count': 'true' };
            }

            return sfHttp.get(filterUrl, request);
        };

        this.fieldsGet = function () {

            const fieldsUrl = config.apiUrl + 'accounts/fields';

            const request = {
                params: {},
                noToast: true,
                noToastForStatusCode: [402, 422]
            };

            return sfHttp.get(fieldsUrl, request);
        };

        this.merge = function (accounts) {

            const url = config.apiUrl + 'accounts/merge';

            return sfHttp.post(url, { accounts }, { ignoreLoadingBar: false });
        };

        this.getSuggestedAccounts = (options) => {

            const url = `${config.apiUrl}accounts/suggestions`;
            const request = {
                cache: true,
                params: {}
            };

            if (options.limit) {
                request.params.limit = options.limit;
            }

            if (options.offset) {
                request.params.offset = options.offset;
            }

            if (options.returnCountOnly) {
                return sfHttp.head(url, request);
            }

            if (options.includeCount) {
                request.headers = { 'x-result-count': 'true' };
            }

            return sfHttp.get(url, request);
        };

        this.updateSuggestedAccount = (suggestedAccountId, update) => {

            return sfHttp.put(`${config.apiUrl}accounts/suggestions/${suggestedAccountId}`, update);
        };

        this.deleteSuggestedAccount = (suggestedAccountId) => {

            return sfHttp.delete(`${config.apiUrl}accounts/suggestions/${suggestedAccountId}`);
        };

        this.getAmountOfNotViewedSuggestedAccounts = function () {

            return sfHttp.get(`${config.apiUrl}accounts/suggestions/new`);
        };
    }
})();
