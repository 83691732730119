/*eslint angular/component-limit: 'off'*/
(function () {
    'use strict';

    /**
     * Use `formatTime` for showing standard hours and minute
     * You probably want to add a title to the date element, use `formatLongDate` for that
     *
     * https://docs.angularjs.org/api/ng/filter/date for date format patterns
     */

    angular
        .module('salesflare')
        .filter('formatDate', ['dateFilter', 'model', formatDate])
        .filter('formatQuickDate', ['dateFilter', 'model', formatQuickDate])
        .filter('formatShortDate', ['dateFilter', 'model', formatShortDate])
        .filter('formatSimpleDate', formatSimpleDate)
        .filter('formatFutureDate', ['dateFilter', 'model', formatFutureDate])
        .filter('formatLongDate', ['dateFilter', 'model', formatLongDate])
        .filter('formatTime', ['dateFilter', 'model', formatTime])
        .filter('timeAgo', timeAgo)
        .filter('timeFromNow', timeFromNow)
        .filter('secondsToDays', secondsToDays)
        .filter('secondsToDaysAndHours', secondsToDaysAndHours)
        .filter('formatToTheSecondRoundedUp', formatToTheSecondRoundedUp);

    function formatDate(dateFilter, model) {

        return function (input) {

            if (!input) {
                return '';
            }

            const date = new Date(input);
            const datemillis = new Date(date).setHours(0, 0, 0, 0);
            const today = new Date();
            const todaymillis = today.setHours(0, 0, 0, 0);

            if (datemillis === todaymillis) {
                return dateFilter(date, pickTimeFormat(model));
            }
            else if (datemillis > todaymillis) {
                return formatFutureDate(dateFilter, 'd MMM')(date);
            }

            return dateFilter(date, 'd MMM');
        };
    }

    function formatQuickDate(dateFilter, model) {

        return function (input) {

            if (!input) {
                return null;
            }

            const date = new Date(input);
            const result = dateFilter(date, 'EEE ' + pickTimeFormat(model));
            return result;
        };
    }

    function formatShortDate(dateFilter, model) {

        return function (input, includePrefix) {

            let prefix = ' \'on\' ';
            if (!input) {
                return null;
            }

            const date = new Date(input);
            const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

            const oneDay = (24 * 60 * 60 * 1000); // Hours*minutes*seconds*milliseconds
            const inputTime = date.getTime();
            const currentTime = Date.now();
            const diffDays = Math.round(Math.abs((inputTime - currentTime) / (oneDay)));
            let dateFormat;

            if (diffDays === 0) {
                prefix = ' \'at\' ';
                dateFormat = pickTimeFormat(model);
            }
            else if (diffDays < 7) {
                return ' on ' + weekDays[date.getDay()];
            }
            else if (date.getFullYear() === new Date().getFullYear()) {
                dateFormat = 'd MMM';
            }
            else {
                dateFormat = 'd MMM y';
            }

            if (includePrefix) {
                dateFormat = prefix + dateFormat;
            }

            return dateFilter(date, dateFormat);
        };
    }

    /**
     * Returns `05 Dec` or `Today` when `showToday === true` and `input` is today
     *
     * @param {Function} dateFilter
     * @returns {Function}
     */
    function formatSimpleDate(dateFilter) {

        return function (input, showToday) {

            if (!input) {
                return null;
            }

            const date = new Date(input);

            let result = dateFilter(date, 'd MMM');

            if (showToday) {
                const oneDay = 24 * 60 * 60 * 1000; // Hours*minutes*seconds*milliseconds
                const inputTime = date.getTime();
                const currentTime = Date.now();
                const diffDays = Math.round(Math.abs((inputTime - currentTime) / (oneDay)));

                if (diffDays === 0) {
                    result = 'Today';
                }
            }

            return result;
        };
    }

    function formatFutureDate(dateFilter, model) {

        return function (input) {

            if (!input) {
                return null;
            }

            //Input = input + " UTC";
            //date = new Date(input.replace(/-/g, "/"));
            const date = new Date(input);
            const datemillis = new Date(date).setHours(0, 0, 0, 0);

            const today = new Date();
            const todaymillis = today.setHours(0, 0, 0, 0);

            const lastweek = new Date();
            lastweek.setDate(today.getDate() + 7);
            const lastweekmillis = lastweek.setHours(0, 0, 0, 0);

            if (datemillis === todaymillis) {
                return dateFilter(date, pickTimeFormat(model));
            }
            else if (datemillis < lastweekmillis) {
                return dateFilter(date, 'EEE');
            }

            return dateFilter(date, 'd MMM');
        };
    }

    /**
     * Returns `Sun 5 December 2017 at 04:20`
     *
     * @param {Function} dateFilter
     * @param {Object} model
     * @returns {Function}
     */
    function formatLongDate(dateFilter, model) {

        return function (input) {

            if (!input) {
                return null;
            }

            //Input = input + " UTC";
            //date = new Date(input.replace(/-/g, "/"));
            const date = new Date(input);

            return dateFilter(date, 'EEE d MMMM y \'at\' ' + pickTimeFormat(model));
        };
    }

    function timeAgo() {

        return function (time, short) {

            if (!time) {
                return null;
            }

            if (angular.isDate(time)) {
                time = time.getTime();
            }
            else if (angular.isString(time)) {
                time = new Date(time).getTime();
            }

            const offset = Math.abs((Date.now() - time) / 1000);
            let span = [];
            const MINUTE = 60;
            const HOUR = 3600;
            const DAY = 86400;
            const WEEK = 604800;
            const MONTH = 2629744;
            const YEAR = 31556926;

            if (offset <= MINUTE) {
                return 'now';
            }
            else if (offset < (MINUTE * 60)) {
                span = [Math.round(Math.abs(offset / MINUTE)), short ? 'min' : 'minute'];
            }
            else if (offset < (HOUR * 24)) {
                span = [Math.round(Math.abs(offset / HOUR)), short ? 'hr' : 'hour'];
            }
            else if (offset < (DAY * 7)) {
                span = [Math.round(Math.abs(offset / DAY)), 'day'];
            }
            else if (offset < (WEEK * 4)) {
                span = [Math.round(Math.abs(offset / WEEK)), short ? 'wk' : 'week'];
            }
            else if (offset < (MONTH * 12)) {
                span = [Math.round(Math.abs(offset / MONTH)), short ? 'mnth' : 'month'];
            }
            else {
                span = [Math.round(Math.abs(offset / YEAR)), short ? 'yr' : 'year'];
            }

            span[1] += (span[0] === 0 || span[0] > 1) ? 's' : '';
            span = span.join(' ');

            return span + ' ago';
        };
    }

    /**
     * Handles both future and past dates
     *
     * @returns {Function}
     */
    function timeFromNow() {

        return function (time, short) {

            if (!time) {
                return null;
            }

            if (angular.isDate(time)) {
                time = time.getTime();
            }
            else if (angular.isString(time)) {
                time = new Date(time).getTime();
            }

            let additionalString = ' from now';
            let offset = (time - Date.now()) / 1000;
            if (offset < 0) {
                offset = Math.abs(offset);
                additionalString = ' ago';
            }

            let span = [];
            const MINUTE = 60;
            const HOUR = 3600;
            const DAY = 86400;
            const WEEK = 604800;
            const MONTH = 2629744;
            const YEAR = 31556926;

            if (offset <= MINUTE) {
                span = ['', 'less than a minute'];
            }
            else if (offset < (MINUTE * 60)) {
                span = [Math.round(Math.abs(offset / MINUTE)), 'min'];
            }
            else if (offset < (HOUR * 24)) {
                span = [Math.round(Math.abs(offset / HOUR)), 'hr'];
            }
            else if (offset < (DAY * 7)) {
                span = [Math.round(Math.abs(offset / DAY)), 'day'];
            }
            else if (offset < (WEEK * 4)) {
                span = [Math.round(Math.abs(offset / WEEK)), short ? 'wk' : 'week'];
            }
            else if (offset < (MONTH * 12)) {
                span = [Math.round(Math.abs(offset / MONTH)), short ? 'mnth' : 'month'];
            }
            else {
                span = [Math.round(Math.abs(offset / YEAR)), short ? 'yr' : 'year'];
            }

            span[1] += (span[0] === 0 || span[0] > 1) ? 's' : '';
            span = span.join(' ');

            return span + additionalString;
        };
    }

    function formatToTheSecondRoundedUp(dateFilter) {

        return function (input) {

            if (!input && input !== 0) {
                return null;
            }

            if (input < 1000) {
                return 'less than a second';
            }

            return dateFilter(new Date(input), 'mm:ss');
        };
    }

    /**
     * Returns something like 11:04 or 01:05 by default
     * Can be enhanced to do am/pm based on the user in the future
     *
     * @param {Function} dateFilter
     * @param {Object} model
     * @returns {Function}
     */
    function formatTime(dateFilter,  model) {

        return function (input) {

            return dateFilter(new Date(input), pickTimeFormat(model));
        };
    }

    /**
     * Function that gives back the right time format
     *
     * @param {Object} model
     * @returns {'h:mm a' | 'HH:mm'}
     */
    function pickTimeFormat(model) {

        if (model.me && model.me.am_pm_notation) {
            return 'h:mm a';
        }

        return 'HH:mm';
    }

    function secondsToDays() {

        return function (input) {
            const days = Math.floor(moment.duration(input, 'seconds').asDays());

            if (days === 0) {
                return 'less than a day';
            }

            return (days + (days === 1 ? ' day' : ' days'));
        };
    }

    function secondsToDaysAndHours() {

        return function (input) {

            if (input < 3600) {
                return 'Less than an hour';
            }

            const formattedParts = [];
            const days = Math.floor(input / ( 3600 * 24 ));
            const hours = Math.floor(input % ( 3600 * 24 ) / 3600);

            if (days > 0) {
                formattedParts.push(days + (days === 1 ? ' day' : ' days'));
            }

            if (hours > 0) {
                formattedParts.push(hours + (hours === 1 ? ' hour' : ' hours'));
            }

            return formattedParts.join(' and ');
        };
    }
})();
