import { Component, Inject, OnInit } from '@angular/core';

@Component({
    selector: 'sfx-terms-policies',
    templateUrl: './terms-and-policies.component.html',
    styleUrls: ['./terms-and-policies.component.scss']
})
export class TermsAndPoliciesComponent implements OnInit {

    constructor(
        @Inject('stateService') public stateService: any
    ) {}

    public ngOnInit(): void {}

    public close = (): any =>  {

        this.stateService.go('settings');
    };
}
