/**
 * @typedef {('account'|'contact'|'opportunity')} EntityType
 * @typedef {{ name: String, order_by: String[], sort_string: String }} SortOption
 */

(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('sortService', sortService);

    function sortService(model) {

        const self = this;

        this.sortOptions = {
            account: {
                last_interaction_date_asc: {
                    name: 'Last interaction, old to new',
                    order_by: ['account.last_interaction_date asc', 'account.modification_date asc'],
                    sort_string: 'last interaction (asc.)'
                },
                last_interaction_date_desc: {
                    name: 'Last interaction, new to old',
                    order_by: ['account.last_interaction_date desc', 'account.modification_date desc'],
                    sort_string: 'last interaction (desc.)',
                    default: true
                },
                name_asc: {
                    name: 'Name, A to Z',
                    order_by: ['account.name asc', 'account.last_interaction_date desc', 'account.modification_date desc'],
                    sort_string: 'name (asc.)'
                },
                name_desc: {
                    name: 'Name, Z to A',
                    order_by: ['account.name desc', 'account.last_interaction_date desc', 'account.modification_date desc'],
                    sort_string: 'name (desc.)'
                },
                creation_date_asc: {
                    name: 'Creation date, old to new',
                    order_by: ['account.creation_date asc', 'account.last_interaction_date desc', 'account.modification_date desc'],
                    sort_string: 'creation date (asc.)'
                },
                creation_date_desc: {
                    name: 'Creation date, new to old',
                    order_by: ['account.creation_date desc', 'account.last_interaction_date desc', 'account.modification_date desc'],
                    sort_string: 'creation date (desc.)'
                }
            },
            contact: {
                name_asc: {
                    name: 'Name, A to Z',
                    order_by: ['person.name asc'],
                    sort_string: 'name (asc.)',
                    default: true
                },
                name_desc: {
                    name: 'Name, Z to A',
                    order_by: ['person.name desc'],
                    sort_string: 'name (desc.)'
                },
                last_name_asc: {
                    name: 'Last name, A to Z',
                    order_by: ['person.lastname asc'],
                    sort_string: 'last name (asc.)'
                },
                last_name_desc: {
                    name: 'Last name, Z to A',
                    order_by: ['person.lastname desc'],
                    sort_string: 'last name (desc.)'
                },
                creation_date_asc: {
                    name: 'Creation date, old to new',
                    order_by: ['person.creation_date asc'],
                    sort_string: 'creation date (asc.)'
                },
                creation_date_desc: {
                    name: 'Creation date, new to old',
                    order_by: ['person.creation_date desc'],
                    sort_string: 'creation date (desc.)'
                }
            },
            // Added the clientSortObject property for the opportunity sort options, which translates the order_by to a property on opportunity objects returned by the server
            // This is needed to check where dragged/dropped opportunity should go in the currently loaded stage opportunities
            opportunity: {
                last_interaction_date_asc: {
                    name: 'Last interaction, old to new',
                    clientSortObject: { property: 'last_interaction.date', direction: 'asc' },
                    order_by: ['stage.order asc', 'last_interaction.modification_date asc'],
                    sort_string: 'last interaction (asc.)'
                },
                last_interaction_date_desc: {
                    name: 'Last interaction, new to old',
                    clientSortObject: { property: 'last_interaction.date', direction: 'desc' },
                    order_by: ['stage.order asc', 'last_interaction.modification_date desc'],
                    sort_string: 'last interaction (desc.)',
                    default: true
                },
                name_asc: {
                    name: 'Name, A to Z',
                    clientSortObject: { property: 'name', direction: 'asc', type: 'string' },
                    order_by: ['stage.order asc', 'opportunity.name asc', 'last_interaction.modification_date desc'],
                    sort_string: 'name (asc.)'
                },
                name_desc: {
                    name: 'Name, Z to A',
                    clientSortObject: { property: 'name', direction: 'desc', type: 'string' },
                    order_by: ['stage.order asc', 'opportunity.name desc', 'last_interaction.modification_date desc'],
                    sort_string: 'name (desc.)'
                },
                account_name_asc: {
                    name: 'Account name, A to Z',
                    clientSortObject: { property: 'account.name', direction: 'asc', type: 'string' },
                    order_by: ['stage.order asc', 'account.name asc', 'last_interaction.modification_date desc'],
                    sort_string: 'account name (asc.)'
                },
                account_name_desc: {
                    name: 'Account name, Z to A',
                    clientSortObject: { property: 'account.name', direction: 'desc', type: 'string' },
                    order_by: ['stage.order asc', 'account.name desc', 'last_interaction.modification_date desc'],
                    sort_string: 'account name (desc.)'
                },
                creation_date_asc: {
                    name: 'Creation date, old to new',
                    clientSortObject: { property: 'creation_date', direction: 'asc' },
                    order_by: ['stage.order asc', 'opportunity.creation_date asc', 'last_interaction.modification_date desc'],
                    sort_string: 'creation date (asc.)'
                },
                creation_date_desc: {
                    name: 'Creation date, new to old',
                    clientSortObject: { property: 'creation_date', direction: 'desc' },
                    order_by: ['stage.order asc', 'opportunity.creation_date desc', 'last_interaction.modification_date desc'],
                    sort_string: 'creation date (desc.)'
                },
                value_asc: {
                    name: 'Value, low to high',
                    clientSortObject: { property: 'calculated_value', direction: 'asc' },
                    order_by: ['stage.order asc', 'opportunity.calculated_value asc', 'last_interaction.modification_date desc'],
                    sort_string: 'value (asc.)'
                },
                value_desc: {
                    name: 'Value, high to low',
                    clientSortObject: { property: 'calculated_value', direction: 'desc' },
                    order_by: ['stage.order asc', 'opportunity.calculated_value desc', 'last_interaction.modification_date desc'],
                    sort_string: 'value (desc.)'
                },
                close_date_asc: {
                    name: 'Close date, old to new',
                    clientSortObject: { property: 'close_date', direction: 'asc' },
                    order_by: ['stage.order asc', 'opportunity.close_date asc', 'last_interaction.modification_date desc'],
                    sort_string: 'close date (asc.)'
                },
                close_date_desc: {
                    name: 'Close date, new to old',
                    clientSortObject: { property: 'close_date', direction: 'desc' },
                    order_by: ['stage.order asc', 'opportunity.close_date desc', 'last_interaction.modification_date desc'],
                    sort_string: 'close date (desc.)'
                },
                probability_asc: {
                    name: 'Probability, low to high',
                    clientSortObject: { property: 'probability', direction: 'asc' },
                    order_by: ['stage.order asc', 'probability asc', 'last_interaction.modification_date desc'],
                    sort_string: 'probability (asc.)'
                },
                probability_desc: {
                    name: 'Probability, high to low',
                    clientSortObject: { property: 'probability', direction: 'desc' },
                    order_by: ['stage.order asc', 'probability desc', 'last_interaction.modification_date desc'],
                    sort_string: 'probability (desc.)'
                },
                start_date_asc: {
                    name: 'Start date, old to new',
                    clientSortObject: { property: 'start_date', direction: 'asc' },
                    order_by: ['stage.order asc', 'start_date asc', 'last_interaction.modification_date desc'],
                    sort_string: 'start_date (asc.)'
                },
                start_date_desc: {
                    name: 'Start date, new to old',
                    clientSortObject: { property: 'start_date', direction: 'desc' },
                    order_by: ['stage.order asc', 'start_date desc', 'last_interaction.modification_date desc'],
                    sort_string: 'start_date (desc.)'
                }
            },
            workflow: {
                creation_date_asc: {
                    name: 'Creation date, old to new',
                    order_by: ['creation_date asc'],
                    sort_string: 'creation date (asc.)'
                },
                creation_date_desc: {
                    name: 'Creation date, new to old',
                    order_by: ['creation_date desc'],
                    sort_string: 'creation date (desc.)',
                    default: true
                },
                name_asc: {
                    name: 'Name, A to Z',
                    order_by: ['name asc', 'creation_date desc'],
                    sort_string: 'name (asc.)'
                },
                name_desc: {
                    name: 'Name, Z to A',
                    order_by: ['name desc', 'creation_date desc'],
                    sort_string: 'name (desc.)'
                }
            },
            tag: {
                name_asc: {
                    name: 'Name, A to Z',
                    order_by: ['name asc', 'creation_date desc'],
                    sort_string: 'name (asc.)',
                    default: true
                },
                name_desc: {
                    name: 'Name, Z to A',
                    order_by: ['name desc', 'creation_date desc'],
                    sort_string: 'name (desc.)'
                },
                times_used_asc: {
                    name: 'Amount of times used, low to high',
                    order_by: ['tag_stats.total_count asc', 'creation_date desc'],
                    sort_string: 'amount of times used (asc.)'
                },
                times_used_desc: {
                    name: 'Amount of times used, high to low',
                    order_by: ['tag_stats.total_count desc', 'creation_date desc'],
                    sort_string: 'amount of times used (desc.)'
                }
            }
        };
        this.currentSortOptions = {};
        this.defaultSortOptions = getDefaultSortOptions();

        function getDefaultSortOptions() {

            const defaultSortOptions = {};

            Object.keys(self.sortOptions).forEach(function (entity) {

                const sortKeys = Object.keys(self.sortOptions[entity]);

                for (const sortKey of sortKeys) {

                    if (self.sortOptions[entity][sortKey].default === true) {
                        defaultSortOptions[entity] = sortKey;
                        break;
                    }
                }
            });

            return defaultSortOptions;
        }

        /**
         * @param {EntityType} entity
         * @returns {SortOption}
         */
        this.getSortOptions = function (entity) {

            return self.sortOptions[entity];
        };

        /**
         * @param {EntityType} entity
         * @param {String} sortKey
         * @returns {undefined}
         */
        this.setCurrentSortKey = function (entity, sortKey) {

            store.set((entity + '_sort_option' + model.me.id), sortKey);
            self.currentSortOptions[entity] = sortKey;
        };

        /**
         * @param {{EntityType}} entity
         * @returns {String}
         */
        this.getCurrentSortKey = function (entity) {

            if (angular.isUndefined(self.currentSortOptions[entity])) {
                self.setCurrentSortKey(entity, store.get((entity + '_sort_option' + model.me.id)) || self.defaultSortOptions[entity]);
            }

            return self.currentSortOptions[entity];
        };

        /**
         * @param {{EntityType}} entity
         * @returns {SortOption}
         */
        this.getCurrentSortOption = function (entity) {

            if (angular.isUndefined(self.currentSortOptions[entity])) {
                self.setCurrentSortKey(entity, store.get((entity + '_sort_option' + model.me.id)) || self.defaultSortOptions[entity]);
            }

            return self.sortOptions[entity][self.currentSortOptions[entity]];
        };
    }
})();
