import { Component, Inject } from '@angular/core';

@Component({
    selector: 'sfx-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss']
})
export class SupportComponent {
    constructor(
        @Inject('emailService') public emailService: any
    ) {}

    public mailTo = this.emailService.mailto;
}
