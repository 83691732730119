(function () {
    'use strict';

    angular
        .module('salesflare.components.filters.operator', [])
        .component('sfFiltersOperator', {
            templateUrl: 'app-ajs/components/filters/operator/filtersOperator.html',
            controllerAs: 'vm',
            controller,
            bindings: {
                propertyChanged: '&',
                property: '<',
                filterFields: '<',
                apply: '&',
                isDisabled: '<',
                noEditAllowed: '<'
            }
        });


    // Way to give back the operator
    function controller($q, $timeout, accounts, contactsService, tagsService, users, customfields, filterService, utils, pipelines, workflowsService) {

        const vm = this;

        let thread = null;
        let valueObject;

        vm.propertyMatches = [];
        vm.filterValueChanged = false;

        // Init all possible operators for the different input types
        vm.$onInit = function () {

            if (vm.property && vm.property.operator && vm.property.type) {
                transformOperatorsAndValues();
            }
            else {
                vm.operator = '';
                vm.value = '';
                vm.valueDate = '';
                vm.valueChip = [];
            }

            if (!valueObject || (angular.isUndefined(valueObject.raw_value) && angular.isUndefined(valueObject.value))) {
                valueObject = {};
            }

            vm.textOperators = filterService.operators.text;

            vm.largeTextOperators = filterService.operators.text;

            vm.urlOperators = filterService.operators.text;

            vm.integerOperators = filterService.operators.integer;

            vm.selectOperators = filterService.operators.select;

            vm.autoCompleteOperators = filterService.operators.select;

            vm.multiSelectOperators = filterService.operators.select;

            vm.tagOperators = filterService.operators.select;

            vm.dateOperators = {
                relative: filterService.operators.date.filter(function (dateOperator) {

                    return dateOperator.key.startsWith('past') || dateOperator.key.startsWith('future');
                }),
                absolute: filterService.operators.date.filter(function (dateOperator) {

                    return !dateOperator.key.startsWith('past') && !dateOperator.key.startsWith('future');
                })
            };

            vm.booleanOperators = filterService.operators.boolean;

            vm.binaryBooleanOperators = filterService.operators.boolean;
        };

        // Set the default properties
        vm.$onChanges = function (changes) {

            // When filter fields change, we need to check if select and multi-select rules are still correct
            if (changes.filterFields && vm.property && vm.property.options) {
                const filterFields = changes.filterFields.currentValue;

                let currentField;

                if (vm.property.archived) {

                    currentField = vm.property;
                }
                else {

                    currentField = filterFields.find(function (filterField) {

                        // If filterField and property pipelines aren't the same, return false
                        // This prevents faults with opportunity custom fields with the same name over different pipelines
                        if (angular.isDefined(vm.property.pipeline)) {
                            if (filterField.pipeline !== vm.property.pipeline) {
                                return false;
                            }
                        }

                        return (filterField.id === vm.property.id || filterField.query_builder_id === vm.property.id);
                    });
                }

                if (vm.property.raw_value?.map((option) => option.archived).includes(true)) {

                    currentField.options = vm.property.options;
                    currentField.raw_value = vm.property.raw_value;
                }

                if (currentField && currentField.options) {
                    // No value available yet
                    if (!vm.property.value) {
                        vm.property.options = currentField.options;
                        vm.selectOptions = vm.property.options;
                    }
                    // Already a value available. Check if options are different
                    else if (
                        angular.isDefined(vm.property)
                            && angular.isDefined(currentField)
                            && !areArrayValuesEqual(vm.property.options, currentField.options, areOptionObjectsEqual)
                    ) {
                        // If options are not the same correct them to the currentField options
                        vm.property.options = currentField.options;
                        vm.selectOptions = vm.property.options;

                        // If we actually changed options (not just initial state set), remove current selected value
                        if (Object.keys(changes.filterFields.previousValue).length > 0) {
                            valueObject = {};
                            vm.property.value = [];
                            delete vm.property.raw_value;
                            vm.selectedValue = [];
                            vm.propertyChanged({
                                $event: {
                                    operator: vm.property.operator
                                }
                            });
                        }
                    }
                }
            }

            if (vm.property && vm.property.options) {
                vm.selectOptions = vm.property.options;
            }

            if (vm.property) {
                if (vm.property.operator) {
                    transformOperatorsAndValues();
                    // When we still get an old operator switch it to just being the key
                    if (vm.property.operator.key) {
                        vm.property.operator = vm.property.operator.key;
                    }

                    vm.operator = vm.property.operator;
                    vm.value = vm.property.value;
                    valueObject = {
                        value: vm.property.value,
                        raw_value: vm.property.raw_value
                    };

                    if (vm.property.input !== 'multiselect' && vm.property.input !== 'tags' && angular.isArray(vm.property.value) && vm.property.value.length === 1) {
                        vm.property.value = vm.property.value[0];

                        if (!angular.isObject(vm.property.value)) {
                            vm.value = vm.property.value;
                        }
                    }

                    // When we still get an old value of type object switch it to the new format
                    if (angular.isObject(vm.property.value) && !angular.isArray(vm.property.value)) {
                        vm.value = vm.property.value.value;
                        valueObject = {
                            value: vm.property.value.value,
                            raw_value: vm.property.value.raw_value
                        };
                        delete vm.property.value;
                        angular.merge(vm.property, valueObject);
                    }

                    if ((vm.property.operator === 'between' || vm.property.operator === 'not_between')
                        && angular.isArray(vm.property.value) && vm.property.value.length === 2) {

                        vm.value = vm.property.value[0];
                        vm.value2 = vm.property.value[1];
                    }

                    // Initialize selected value(s) for select fields
                    if ((vm.property.input === 'single select' || vm.property.input === 'multiselect') && !vm.selectedValue) {
                        vm.selectedValue = [];
                    }

                    switch (vm.property.input) {
                        case 'single select':
                            if (vm.selectedValue.length !== valueObject.value.length) {
                                vm.selectedValue = valueObject.value;
                            }

                            break;
                        case 'multiselect':
                            if (vm.selectedValue && vm.value && (
                                (vm.selectedValue.length !== vm.value.length)
                                ||
                                (!vm.selectedValue && vm.value)
                            )) {
                                vm.selectedValue = vm.value;
                            }

                            break;
                        case 'autocomplete':
                            vm.valueChip = valueObject.raw_value ? valueObject.raw_value : [];
                            break;
                        case 'tags':
                            vm.valueChip = valueObject.raw_value ? valueObject.raw_value : [];
                            break;
                        case 'date':
                            if (vm.operator === 'after' || vm.operator === 'before' || vm.operator === 'on') {
                                vm.valueDate = utils.UTCDateStringToLocalDateObject(valueObject.value);
                            }

                            break;
                        default:
                            break;
                    }
                }
                else {
                    // Default operators

                    // eslint-disable-next-line no-lonely-if
                    if (vm.property.input === 'text' || vm.property.input === 'largetext' || vm.property.input === 'url') {
                        vm.operator = 'contains';
                        return vm.changeProperty();
                    }
                    else if (vm.property.input === 'integer' || vm.property.input === 'decimal') {
                        vm.operator = 'equal';
                        return vm.changeProperty();
                    }
                    else if (vm.property.input === 'single select' || vm.property.input === 'multiselect' || vm.property.input === 'autocomplete' || vm.property.input === 'tags') {
                        vm.operator = 'in';
                        return vm.changeProperty();
                    }
                    else if (vm.property.input === 'date') {
                        vm.operator = 'past_exactly';
                        return vm.changeDateProperty();
                    }
                    else if (vm.property.input === 'radio' || vm.property.input === 'binaryradio') {
                        vm.operator = 'true';
                        return vm.changeProperty();
                    }
                }
            }
        };

        // Send the news of an operator change to filtersRule
        vm.changeProperty = function () {

            if (
                vm.operator === 'is_empty' || vm.operator === 'is_not_empty'
                || vm.operator === 'is_null' || vm.operator === 'is_not_null'
                || vm.operator === 'true' || vm.operator === 'false'
            ) {
                vm.propertyChanged({
                    $event: {
                        operator: vm.operator,
                        value: ''
                    }
                });
                vm.filterValueChanged = false;
                return vm.apply();
            }

            // If the operator normally accepts a single value, but now holds an array
            // reset the value, to avoid errors
            // operators that do accept arrays should be added to the if statement
            if (
                vm.operator !== 'in' && vm.operator !== 'not_in'
                && vm.operator !== 'between' && vm.operator !== 'not_between'
                && angular.isArray(valueObject.value)
            ) {
                valueObject.value = vm.value;
                delete vm.value2;
                vm.changePropertyValue();
            }

            const newProperty = angular.copy(valueObject);
            newProperty.operator = vm.operator;
            vm.propertyChanged({ $event: newProperty });

            for (const propertyName in valueObject) {
                if (valueObject[propertyName] !== '' && angular.isDefined(valueObject[propertyName])) {
                    vm.filterValueChanged = false;
                    return vm.apply();
                }
            }
        };

        // Function that gets called on a value change for the rule
        vm.changePropertyValue = function () {

            if (!vm.filterValueChanged) {
                vm.filterValueChanged = true;
            }

            switch (vm.property.input) {
                case 'integer':
                case 'decimal':
                    if (vm.operator === 'between' || vm.operator === 'not_between') {
                        valueObject.value = [vm.value, vm.value2];
                    }
                    else {
                        valueObject.value = vm.value;
                    }

                    break;
                case 'single select': {
                    valueObject.value = vm.selectedValue.id;
                    const newPropertySingle = angular.copy(valueObject);
                    newPropertySingle.operator = vm.operator;

                    vm.propertyChanged({ $event: newPropertySingle });

                    vm.filterValueChanged = false;

                    return vm.apply();
                }

                case 'multiselect':
                    if (!areArrayValuesEqual(valueObject.value, vm.selectedValue)) {
                        valueObject.value = vm.selectedValue;
                        const newPropertyMulti = angular.copy(valueObject);
                        newPropertyMulti.operator = vm.operator;

                        vm.propertyChanged({ $event: newPropertyMulti });

                        vm.filterValueChanged = false;

                        return vm.apply();
                    }

                    return;
                case 'autocomplete': {
                    const valueIds = vm.valueChip.map(function (chip) {

                        return chip.id;
                    });
                    valueObject.value = valueIds;
                    valueObject.raw_value = vm.valueChip;
                    const newPropertyAuto = angular.copy(valueObject);
                    newPropertyAuto.operator = vm.operator;
                    vm.propertyChanged({ $event: newPropertyAuto });

                    vm.filterValueChanged = false;

                    return vm.apply();
                }

                case 'tags': {
                    valueObject.value = vm.valueChip.map(function (value) {

                        return value.id;
                    });
                    valueObject.raw_value = vm.valueChip;
                    const newPropertyTags = angular.copy(valueObject);
                    newPropertyTags.operator = vm.operator;
                    vm.propertyChanged({ $event: newPropertyTags });

                    vm.filterValueChanged = false;

                    return vm.apply();
                }

                case 'date':
                    if (vm.operator === 'after' || vm.operator === 'before' || vm.operator === 'on') {
                        valueObject.value = vm.valueDate;
                        if (vm.value) {
                            vm.value = '';
                        }
                    }
                    else {
                        valueObject.value = vm.value;
                        if (vm.valueDate) {
                            vm.valueDate = '';
                        }
                    }

                    break;
                default:
                    valueObject.value = vm.value;
            }

            const newProperty = angular.copy(valueObject);
            newProperty.operator = vm.operator;
            vm.propertyChanged({ $event: newProperty });

            $timeout.cancel(thread);

            thread = $timeout(function () {

                return vm.applyWrapper();
            }, 750);
        };

        /**
         * Helper function to check if two array values are different
         *
         * @param {[]} originalArray
         * @param {[]} newArray
         * @param {function(*, *):Boolean} [compareFunction]
         * @returns {Boolean}
         */
        function areArrayValuesEqual(originalArray, newArray, compareFunction) {

            if (angular.isUndefined(originalArray) && angular.isUndefined(newArray)) {
                return true;
            }

            if ((!originalArray && angular.isDefined(newArray)) || (!newArray && angular.isDefined(originalArray))) {
                return false;
            }

            if (originalArray.length !== newArray.length) {
                return false;
            }

            for (const [i, element] of originalArray.entries()) {
                if (angular.isDefined(compareFunction) && !compareFunction(element, newArray[i])) {
                    return false;
                }
                else if (angular.isUndefined(compareFunction) && element !== newArray[i]) {
                    return false;
                }
            }

            return true;
        }

        /**
         * Helper function to check if two options objects are equal
         *
         * @param {{id: Number, name: String, order: Number}} originalObject
         * @param {{id: Number, name: String, order: Number}} newObject
         * @returns {Boolean}
         */
        function areOptionObjectsEqual(originalObject, newObject) {

            if (
                originalObject.id !== newObject.id
                || originalObject.name !== newObject.name
                || originalObject.order !== newObject.order
                || originalObject.archived !== newObject.archived
            ) {
                return false;
            }

            return true;
        }

        // An other function is needed, otherwise we get overlap with values for the same input field (absolute and relative date stuff)
        vm.changeDateProperty = function () {

            if (vm.value && !vm.valueDate) {
                vm.value = '';
            }

            return vm.propertyChanged({
                $event: {
                    operator: vm.operator,
                    value: {
                        value: vm.valueDate
                    }
                }
            });
        };

        // Searches for values available for auto-complete inputs
        vm.searchPropertyValue = function (queryString) {

            if (!queryString || queryString === '') {
                queryString = undefined;
            }

            // We use the query builder id if it is there so older clients can talk to a newer server
            const forwardCompatibleId = vm.property.query_builder_id || vm.property.id;

            if (queryString && forwardCompatibleId.includes('custom')) {
                if (vm.property.search_entity) {
                    switch (vm.property.search_entity) {
                        case 'account':
                            return accounts.get(queryString).then(searchResponse, null, searchResponse);
                        case 'contact':
                            return contactsService.get(queryString).then(searchResponse, null, searchResponse);
                        case 'user':
                            return users.get(queryString, false, ['user.disabled', 'name']).then(searchResponse, null, searchResponse);
                    }
                }

                const customFieldName = vm.property.name ? vm.property.name : vm.property.label;
                return customfields.getOptions(customFieldName, vm.property.entity_ui.toLowerCase(), queryString).then(searchResponse, null, searchResponse);
            }

            switch (forwardCompatibleId) {
                case 'account.owner':
                case 'account-user.user':
                case 'campaign.creator':
                case 'opportunity.assignee':
                case 'opportunity.creator':
                case 'opportunity.owner':
                case 'task.creator':
                case 'task.completor':
                case 'task_assignee.assignee':
                case 'account.owner.id': //New id's
                case 'account.team_member.id':
                case 'campaign.created_by.id':
                case 'opportunity.assignee.id':
                case 'opportunity.created_by.id':
                case 'opportunity.owner.id':
                case 'task.created_by.id':
                case 'task.completed_by':
                case 'task.assignee.id':
                case 'workflow.created_by.id':
                case 'contact.strongest_relationship':
                case 'account.strongest_relationship':
                    return users.get(queryString, false, ['user.disabled', 'name']).then(searchResponse, null, searchResponse);
                case 'account-contact.account':
                case 'opportunity.account':
                case 'task.account':
                case 'contact.account.id'://New id's
                case 'opportunity.account.id':
                case 'task.account.id':
                    return accounts.get(queryString).then(searchResponse, null, searchResponse);
                case 'account-contact.contact':
                case 'campaign-opened.contact':
                case 'campaign-clicked.contact':
                case 'campaign-replied.contact':
                case 'campaign_email.contact':
                case 'account.contact.id'://New id's
                case 'campaign.opened_by.id':
                case 'campaign.clicked_by.id':
                case 'campaign.replied_by.id':
                case 'campaign.contact.id':
                case 'workflow.entered.id':
                case 'workflow.in.id':
                case 'workflow.exited.id':
                case 'workflow.met_goal.id':
                case 'workflow.contact.id':
                    return contactsService.get(queryString).then(searchResponse, null, searchResponse);
                case 'account-tag.id':
                case 'opportunity-tag.id':
                case 'person-tag.id':
                case 'account.tag.id'://New id's
                case 'opportunity.tag.id':
                case 'contact.tag.id':
                    return tagsService.get({ search: queryString }).then(searchResponse, null, searchResponse);
                case 'pipeline.id':
                case 'opportunity.pipeline.id': //New id
                    return pipelines.get(queryString).then(searchResponse, null, searchResponse);
                case 'workflow_record.send_email.received':
                case 'workflow_record.send_email.not_received':
                case 'workflow_record.send_email.opened':
                case 'workflow_record.send_email.not_opened':
                case 'workflow_record.send_email.clicked':
                case 'workflow_record.send_email.not_clicked':
                case 'workflow_record.send_email.replied':
                case 'workflow_record.send_email.not_replied':
                    return workflowsService.get({ search: queryString }, {}).then(searchResponse, null, searchResponse);
                default:
                    return [];
            }
        };

        function searchResponse(response) {

            const responseData = response.data.filter(function (result) {

                let filterOut = false;
                vm.valueChip.forEach(function (value) {

                    if (value.id === result.id) {
                        filterOut = true;
                    }

                });
                return !filterOut;
            });

            return $q.resolve(responseData);
        }

        // For some operators, no input field is needed
        vm.showInputField = function () {

            if (
                vm.operator &&
                (
                    vm.operator  !== 'is_empty'
                    && vm.operator !== 'is_not_empty'
                    && vm.operator !== 'is_null'
                    && vm.operator !== 'is_not_null'
                )
            ) {
                return true;
            }

            return false;
        };

        vm.applyWrapper = function () {

            // Only apply a filter when the property value has changed
            if (vm.filterValueChanged) {
                vm.filterValueChanged = false;
                return vm.apply();
            }
        };

        /**
         * This can take API valid rules and translates them to client rules
         */
        function transformOperatorsAndValues() {

            if (angular.isUndefined(vm.property.operator) || angular.isUndefined(vm.property.value)) {
                return;
            }

            const operator = angular.isDefined(vm.property.operator.key) ? vm.property.operator.key : vm.property.operator;
            let value = angular.isDefined(vm.property.value.value) ? vm.property.value.value : vm.property.value;

            if (angular.isArray(value)) {
                value = value[0];
            }

            if (operator === 'equal' || operator === 'is') {
                switch (value) {
                    case '':
                        vm.property.operator = 'is_empty';
                        vm.property.value = '';
                        break;

                    case 'null':
                        if (vm.property.input === 'text' || vm.property.input === 'largetext' || vm.property.input === 'integer' || vm.property.input === 'decimal' || vm.property.input === 'url') {
                            vm.property.operator = 'is_null';
                            vm.property.value = '';
                        }
                        else if (vm.property.input === 'date') {
                            vm.property.operator = 'is_null';
                            vm.property.value = '';
                        }
                        else if (vm.property.input === 'binaryradio' && operator === 'equal') {
                            vm.property.operator = 'false';
                            vm.property.value = '';
                        }
                        else {
                            vm.property.operator = 'is_null';
                            vm.property.value = '';
                        }

                        break;

                    case 'true':
                        vm.property.operator = 'true';
                        vm.property.value = '';
                        break;

                    case 'false':
                        vm.property.operator = 'false';
                        vm.property.value = '';
                        break;

                    default:
                        break;
                }
            }

            if (operator === 'not_equal' || operator === 'is_not') {
                switch (value) {
                    case '':
                        vm.property.operator = 'is_not_empty';
                        vm.property.value = '';
                        break;

                    case 'null':
                        if (vm.property.input === 'text' || vm.property.input === 'largetext' || vm.property.input === 'integer' || vm.property.input === 'decimal' || vm.property.input === 'url') {
                            vm.property.operator = 'is_not_null';
                            vm.property.value = '';
                        }
                        else {
                            vm.property.operator = 'is_not_null';
                            vm.property.value = '';
                        }

                        break;

                    default:
                        break;
                }
            }
        }
    }
})();
