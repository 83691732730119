import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'sfx-section',
    templateUrl: './section.component.html',
    styleUrls: ['./section.component.scss']
})
export class SectionComponent {

    private _disableContentPadding = false;

    @HostBinding('style.padding') padding = '16px';

    constructor() {}

    @Input()
    get disableContentPadding(): boolean {
        return this._disableContentPadding;
    }
    set disableContentPadding(value: boolean | string) {
        this._disableContentPadding = typeof value === 'boolean' ? value : !!(value === '' || value);

        this.padding = this.disableContentPadding ? '' : '16px';
    }
}
