(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('ExportFieldsDialogController', ExportFieldsDialogController);

    function ExportFieldsDialogController($mdDialog, $mdMedia, accounts, contactsService, opportunities, tasks) {

        const vm = this;

        vm.fieldsList = null;
        vm.displayFields = null;

        vm.exportType = 'default';

        vm.$mdMedia = $mdMedia;

        vm.back = $mdDialog.cancel;

        vm.getFields = () => {
            vm.doneLoading = false;

            let getFieldsForEntity;

            switch (vm.entity) {
                case 'accounts':
                    getFieldsForEntity = accounts.fieldsGet.bind(this);
                    break;
                case 'contacts':
                    getFieldsForEntity = contactsService.fieldsGet.bind(this);
                    break;
                case 'opportunity':
                    getFieldsForEntity = opportunities.fieldsGet.bind(this);
                    break;
                case 'tasks':
                    getFieldsForEntity = tasks.fieldsGet.bind(this);
                    break;
                default:
                    getFieldsForEntity = accounts.fieldsGet.bind(this);
                    break;
            }

            getFieldsForEntity().then((response) => {

                vm.fieldsList = response.data;
                vm.exportTypeChange();

                vm.doneLoading = true;
            });
        };

        vm.getFields();

        vm.exportTypeChange = function () {

            if (vm.exportType === 'default') {
                vm.fields = 'default';

                vm.displayFields = vm.fieldsList.filter((field) => !!field.label).filter((field) => field.default === true).map((field) => field.label).join(', ');
            }
            else if (vm.exportType === 'all') {
                vm.fields = 'all';

                vm.displayFields = vm.fieldsList.filter((field) => !!field.label).map((field) => field.label).join(', ');
            }
        };

        vm.export = function () {

            if (!vm.exportFieldsForm.$valid) {
                return;
            }

            return $mdDialog.hide({
                exportFields: vm.exportType
            });
        };
    }
})();
