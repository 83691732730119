import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective, NgModel } from '@angular/forms';
import { MediaService } from '@core/services/media.service';
import { MatDialog } from '@angular/material/dialog';

import { SfxEnableTwoFADialog } from '@feature/settings/components/signin-security/enable-2fa-dialog/enable-two-f-a-dialog.component';
import { SfxRecoveryCodesDialog } from '@feature/settings/components/signin-security/recovery-codes-dialog/recovery-codes-dialog.component';

import * as zxcvbn from 'zxcvbn';

@Component({
    selector: 'sfx-signin-security',
    templateUrl: './signin-security.component.html',
    styleUrls: ['./signin-security.component.scss']
})
export class SigninSecurityComponent implements OnInit {

    @ViewChild('passwordForm') passwordForm!: FormGroup;
    @ViewChild('passwordForm') passwordFormDirective!: FormGroupDirective;
    @ViewChild('newPasswordInput') newPasswordInput!: NgModel;

    // State management
    saving = false;
    twoFAEnabled = false;
    // Properties
    newPassword = '';
    confirmPassword = '';

    strength = 0;

    showStrength = !!this.newPassword;

    private token;

    // Methods
    constructor(
        @Inject('authenticationService') private authenticationService: any,
        @Inject('dialogService') private dialogService: any,
        @Inject('modelService') private modelService: any,
        @Inject('passwordService') private passwordService: any,
        @Inject('stateParams') private stateParams: any,
        @Inject('stateService') private stateService: any,
        @Inject('userService') private userService: any,
        @Inject('utilsService') private utilsService: any,
        public media: MediaService,
        public dialog: MatDialog
    ) {
        this.token = stateParams.t;
    }

    public ngOnInit() {
        this.twoFAEnabled = this.modelService.me.two_fa_enabled;
    }

    public onSubmit(): void {

        this.saving = true;

        return this.passwordService.update(this.newPassword, this.token).then((response: any) => {

            this.saving = false;

            this.modelService.setToken(response.data.token);

            return this.authenticationService.fetchMe().then(() => {

                if (this.token) {
                    this.modelService.isLoggedIn = true;

                    return this.stateService.go('tasks.today');
                }

                this.newPassword = '';
                this.confirmPassword = '';

                /* This doesn't seem to be working as expected. Validation errors are still shown.
                this.passwordForm.markAsUntouched();
                this.passwordForm.controls.newPassword.markAsUntouched();
                this.passwordForm.controls.confirmPassword.markAsUntouched();
                */

                this.passwordForm.reset();
                this.passwordFormDirective.resetForm();

                this.utilsService.showSuccessToast('Password changed');
            });
        }).catch(() => {

            this.saving = false;
        });
    }

    public HasToken(): boolean {
        return !!this.token;
    }

    get PasswordSectionTitle(): string {
        return this.HasToken() ? 'Reset password' : 'Change password';
    }

    public evaluatePassword(): void {
        if (this.newPasswordInput.errors || this.newPassword) {
            this.showStrength = true;
        }
        else {
            this.showStrength = false;
        }

        const evaluation = zxcvbn(this.newPassword || '');

        this.strength = evaluation.score * 25;

        if (this.strength < 75) {
            this.passwordForm.controls.newPassword.setErrors({ ...this.passwordForm.controls.newPassword.errors, 'passwordStrength': true });
        }
        else {
            this.passwordForm.controls.newPassword.setErrors({ ...this.passwordForm.controls.newPassword.errors, 'passwordStrength': null });
            this.passwordForm.controls.newPassword.updateValueAndValidity();
        }
    }

    public twoFAChange = (e: any) => {

        e.source.checked = this.twoFAEnabled;

        if (this.twoFAEnabled) {
            const confirm = this.dialogService.confirm({ multiple: true })
                .clickOutsideToClose(true)
                .textContent('Are you sure you want to disable two-factor authentication?')
                .ok('Yes')
                .cancel('No');

            return this.dialogService.show(confirm)
                .then(() => {

                    e.source.checked = !this.twoFAEnabled;
                    this.twoFAEnabled = !this.twoFAEnabled;

                    this.userService.disableTwoFA()
                        .then(() => this.authenticationService.fetchMe());
                });
        }

        return this.userService.getTwoFA().then((response: any) => {

            if (response.status === 200) {
                const dialog = this.dialog.open(SfxEnableTwoFADialog, {
                    panelClass: 'sfx-dialog-container',
                    data: { theme: 'blue', secret: response.data.secret, qrUrl: response.data.qr_url },
                    width: '500px'
                });

                dialog.afterClosed().subscribe((result) => {

                    if (result === 'enabled') {
                        // The switch and model value can now be set to true
                        e.source.checked = !this.twoFAEnabled;
                        this.twoFAEnabled = !this.twoFAEnabled;

                        this.authenticationService.fetchMe();

                        this.dialog.open(SfxRecoveryCodesDialog, {
                            panelClass: 'sfx-dialog-container',
                            width: '500px',
                            height: '500px'
                        });
                    }
                });
            }
        });
    }

    public showRecoveryCodes = () => {

        this.dialog.open(SfxRecoveryCodesDialog, { panelClass: 'sfx-dialog-container', width: '500px', height: '500px' });
    }
}
