(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('ContactsController', ContactsController);

    function ContactsController($rootScope, $scope, $timeout, $document, $mdMedia, $mdDialog, $mdSidenav, $state, $stateParams, $interval, utils, contact, flagsService, filterService, contactsService, model, sortService) {

        $scope.isContactFilterApplied = filterService.isContactFilterApplied;
        $scope.sortOptions = sortService.getSortOptions('contact');
        $scope.searchObject = {
            search: $stateParams.search,
            getSearch: function () {

                return this.search;
            },
            setSearch: function (s) {

                this.search = s;

                return utils.setSearchInUrl({ search: this.search });
            }
        };
        $scope.searchMode = !!$stateParams.search;
        $scope.sideNavObject = { showSideNav: false };
        $scope.notViewedContacts = null;
        $scope.selectedPerson = {
            id: $state.params.id,
            type: $state.params.type || 'contact'
        };
        $scope.me = model.me;

        // Since this still uses scope inheritance we set an object on the scope that contactListController can overwrite
        // Don't do this anywhere else, I just don't have the time to convert everything to components now
        $scope.bulk = {
            allSelected: false,
            showBulkToolbar: false,
            onSelectAll: angular.noop,
            bulkQuickActions: [],
            bulkMenuItems: []
        };
        $scope.type = {
            current: undefined
        };
        $scope.filters = filterService.getFilter('contact', { raw: true });

        $scope.badFilterError = null;

        $scope.getDefaultFilters = function () {

            return filterService.getDefaultFilters('contact', model.me);
        };

        $scope.onFilterApply = function ($event) {

            $scope.filters = filterService.setFilter('contact', $event.rules, $event.saved_filter_id);

            if ($scope.badFilterError) {
                return;
            }

            return get();
        };

        let thread = null;
        let contactsTour = null;

        if (flagsService.get('showContactsWalkthrough') === true) {
            showWalkthrough();
        }

        $scope.$on('refreshData', function () {

            $scope.bulk.showBulkToolbar = false;
            return get();
        });

        // Poll every minute for new customers/my contacts
        const newContactsPollingInterval = $interval(setAmountOfNotViewedContacts, 60 * 1000);
        setAmountOfNotViewedContacts();

        $scope.$on('$destroy', function () {

            contactsTour?.cancel();

            return $interval.cancel(newContactsPollingInterval);
        });

        $scope.setFilters = function () {

            return $mdSidenav('filters').toggle()
                .then(function () {

                    return $mdSidenav('filters', true).onClose(function () {

                        // Return get();
                    });
                });
        };

        $scope.goToTab = function (tab) {

            const state = 'contacts.' + tab;

            if (!$state.includes(state)) {
                $scope.selectedPerson.id = undefined;
                return $state.go(state);
            }
        };

        $scope.create = function () {

            return $state.go('createContact', { createContact: true });
        };

        $scope.$watch('searchMode', function (newValue, oldValue) {

            if (newValue) {
                let searchBoxId = 'searchbox';
                if ($mdMedia('gt-sm')) {
                    searchBoxId += '-fs';
                }

                utils.forceFocus($document[0].querySelector('#' + searchBoxId));
            }

            if (!oldValue && newValue) {
                $scope.searchObject.setSearch('');
            }

            if (oldValue && $scope.searchObject.getSearch()) {
                if (!newValue) {
                    $scope.searchObject.setSearch('');
                }

                return get();
            }
        });

        $scope.doSearch = function (search) {

            $timeout.cancel(thread);

            thread = $timeout(function () {

                $scope.searchObject.setSearch(search);

                return get();
            }, 750);
        };

        $scope.setSearchMode = function (mode) {

            $scope.searchMode = mode;
        };

        $scope.export = function (type) {

            const isCSV = type !== 'excel';
            const isExcel = type === 'excel';

            let filterRules;

            // Do not filter when searching
            if ($scope.searchObject.getSearch()) {
                filterRules = [];
            }
            else {
                filterRules = [...filterService.getFilter('contact'), ...filterService.getPredefinedFilters('person')[$scope.type.current]];
            }

            const filterGetOptions = {
                search: $scope.searchObject.getSearch(),
                filterRules,
                limit: 100000,
                offset: 0,
                orderBy: sortService.getCurrentSortOption('contact').order_by,
                downloadCSV: isCSV,
                downloadExcel: isExcel
            };

            const exportFieldsDialog = $mdDialog.exportFieldsDialog({
                locals: {
                    entity: 'contacts'
                },
                fullscreen: !$mdMedia('gt-sm')
            });

            return $mdDialog.show(exportFieldsDialog).then(function (exportConfig) {
                filterGetOptions.exportFields = exportConfig.exportFields;

                return contactsService.filterGet(filterGetOptions);
            });
        };

        $scope.createWorkflow = function () {

            const options = {
                filter: {}
            };

            if ($scope.searchObject.getSearch()) {
                options.filter = {
                    condition: 'AND',
                    rules: [],
                    search: $scope.searchObject.getSearch()
                };
            }
            else {
                const contactFilterRules = filterService.getFilter('contact', { raw: true });


                if (contactFilterRules.length === 0) {
                    options.filter = null; // Let users build their own audience
                }
                else {
                    options.filter = {
                        condition: 'AND',
                        rules: [...contactFilterRules, ...filterService.getPredefinedFilters('person')[$scope.type.current]]
                    };
                }
            }

            return $state.go('composeWorkflow', options);
        };

        function get() {

            return $scope.$broadcast('get');
        }

        function setAmountOfNotViewedContacts() {

            return contact.getAmountOfNotViewedContacts().then(function (response) {

                $scope.notViewedContacts = response.data;
            });
        }

        function showWalkthrough() {

            $timeout(function () {

                contactsTour = new Shepherd.Tour({
                    defaultStepOptions: {
                        popperOptions: {
                            // Adds an offset of 12px to the tooltip so the arrow (instead of the content) starts on the target element
                            modifiers: [{ name: 'offset', options: { offset: [0, 12] } }]
                        }
                    }
                });

                const text = 'Customers are contacts that have been added to accounts. It\'s a shared address book with your team.\n\nMy Contacts is your full and personal list of contacts, based on your email communication and meetings. It\'s an automated database you can start from.\n\nBoth are fully searchable. Just hit the toolbar at the top.';

                contactsTour.addStep({
                    id: 'explain-contacts',
                    title: 'Welcome to your contacts!',
                    text,
                    attachTo: {
                        element: $mdMedia('gt-sm') ? '#contacts .sf-top-toolbar .md-toolbar-tools' : '#contacts md-tabs',
                        on: 'bottom'
                    },
                    buttons: [{
                        text: 'GOT IT!',
                        action: contactsTour.next
                    }]
                });

                contactsTour.on('complete', function () {

                    return flagsService.set('showContactsWalkthrough', false);
                });

                return contactsTour.start();
            }, 1000);
        }

        $scope.handleFilterError = ($event) => {

            if ($event.message === null && $scope.badFilterError) {
                get();
            }

            $scope.badFilterError = $event.message;
        };

        $scope.handleServerError = (filters) => {

            $scope.$broadcast('setFilters', filters);
        };

        /**
         * Closes contact info
         * On mobile this will go back to the list view
         * On larger screens this will close the sidenav
         *
         * @returns {void}
         */
        $scope.closeContactInfo = function () {

            if ($mdMedia('gt-sm')) {
                $mdSidenav('right').close();
            }

            delete $scope.selectedPerson.id;

            if ($state.current.name.includes('customers')) {
                return $state.go('contacts.customers');
            }

            if ($state.current.name.includes('my')) {
                return $state.go('contacts.my');
            }

            return $rootScope.back();
        };

        $scope.selectSortOption = function (sortKey) {

            sortService.setCurrentSortKey('contact', sortKey);

            return $scope.$broadcast('get');
        };

        $scope.isSortOptionSelected = function (sortKey) {

            return sortService.getCurrentSortKey('contact') === sortKey;
        };
    }
})();
