<sfx-navigation-toolbar (closeEvent)="close()">
    <a navigationToolbarButton icon="sf-icon-article" ref=".terms">
        Terms
    </a>
    <a navigationToolbarButton icon="sf-icon-privacy" ref=".privacy">
        Privacy Policy
    </a>
</sfx-navigation-toolbar>

<sfx-content>
    <sfx-section *ngIf="stateService.is('settings.terms.terms')" disableContentPadding>
        <iframe data-flex src="https://salesflare.com/terms" sandbox="allow-popups allow-scripts" style="border: 0;"></iframe>
    </sfx-section>
    <sfx-section *ngIf="stateService.is('settings.terms.privacy')" disableContentPadding>
        <iframe data-flex src="https://salesflare.com/privacy" sandbox="allow-popups allow-scripts" style="border: 0;"></iframe>
    </sfx-section>
</sfx-content>
