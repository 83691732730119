(function () {
    'use strict';

    angular
        .module('salesflare.components.person', [])
        .component('sfPerson', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/person/person.html',
            bindings: {
                personId: '<',
                type: '<',
                onClose: '&'
            }
        });

    function controller($filter, $state, $stateParams, $mdDialog, $mdMedia, $scope, $window, contact, email, user, model, helperFunctionsService, utils, workflowService, flagsService) {

        const vm = this;

        vm.$mdMedia = $mdMedia;
        vm.showEditButton = true;
        vm.me = model.me;
        vm.insufficientPermissionsMessage = 'You don\'t have permission to edit this contact. Ask an admin.';
        vm.timelineHasRendered = false;
        vm.showNoAccessEmptyState = false;
        vm.isRelationshipVisualizationEnabled = (flagsService.get('relationshipVisualization') === true);

        vm.$onInit = function () {

            vm.personId = vm.personId || $stateParams.id;
            vm.type = vm.type || $stateParams.type;

            if (vm.personId) {
                return get();
            }
        };

        vm.$onChanges = function (changes) {

            // The `isFirstChange` prevents a double get on load where we run onInit and the personId get initialized
            if (vm.personId && !changes.personId.isFirstChange() && (!changes.personId || changes.personId.currentValue !== changes.personId.previousValue)) {
                return get();
            }
        };

        $scope.$on('refreshData', function () {

            if (vm.personId) {
                return get();
            }
        });

        vm.edit = function () {

            if (vm.type === 'contact') {
                $state.go('editContact', angular.merge({}, $stateParams, { id: vm.person.id }));
            }

            if (vm.type === 'user') {
                $state.go('editUser', angular.merge({}, $stateParams, { id: vm.person.id }));
            }
        };

        vm.back = function () {

            return vm.onClose();
        };

        vm.goToAccount = function (account, event) {

            // Open in new tab when clicking on the shortcut icon
            if (event?.target?.classList.contains('open-account-in-new-tab-icon')) {
                const url = $state.href('accounts.account.feed', { id: account.id, name: account.name });
                $window.open(url, '_blank', 'noopener');
                return;
            }

            return $state.go('accounts.account.feed',{ id: account.id, name: account.name });
        };

        vm.goToUser = function (userId) {

            return $state.go('user',{ id: userId });
        };

        vm.skype = helperFunctionsService.skype;

        vm.mailto = email.mailto;

        vm.goToWorkflow = (workflowId) => {

            return $state.go('workflow', { id: workflowId });
        };

        vm.getWorkflowTooltipText = (workflow) => {

            let tooltipText = workflow.name;

            $filter('date')(new Date(workflow.exit_date), 'medium');

            if (workflow.exit_date && !workflow.met_goal_date) {
                tooltipText += `\nExited on ${$filter('formatLongDate')(new Date(workflow.exit_date))}`;
            }
            else if (workflow.met_goal_date) {
                tooltipText += `\nMet goal on ${$filter('formatLongDate')(new Date(workflow.met_goal_date))}`;
            }
            else {
                tooltipText += `\nEntered on ${$filter('formatLongDate')(new Date(workflow.entered_date))}`;
            }

            return tooltipText;
        };

        vm.onExit = ($event) => {

            const exitedFromWorkflow = vm.person.workflows.find((workflow) => workflow.id === $event.workflowId);

            if (exitedFromWorkflow) {
                exitedFromWorkflow.exit_date = $event.exitDate;
                exitedFromWorkflow.met_goal_date = $event.metGoalDate;
            }
        };

        vm.onReEnter = ($event) => {

            const reEnteredInWorkflow = vm.person.workflows.find((workflow) => workflow.id === $event.workflowId);

            if (reEnteredInWorkflow) {
                reEnteredInWorkflow.exit_date = $event.exitDate;
                reEnteredInWorkflow.met_goal_date = $event.metGoalDate;
            }
        };

        vm.showMoreWorkflows = function () {

            return $mdDialog.show({
                controllerAs: 'vm',
                fullscreen: !$mdMedia('gt-sm'),
                multiple: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                templateUrl: 'app-ajs/components/workflows/workflow/workflowsoverviewdialog/workflowsOverviewDialog.html',
                controller: 'WorkflowsOverviewDialogController',
                locals: {
                    workflows: vm.person.workflows,
                    personId: vm.person.id
                },
                bindToController: true
            });
        };

        vm.addContactToWorkflow = function (contactId) {

            return $mdDialog.show({
                controllerAs: 'vm',
                fullscreen: !$mdMedia('gt-sm'),
                multiple: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                templateUrl: 'app-ajs/components/workflows/workflow/addcontacttoworkflowdialog/addContactToWorkflowDialog.html',
                controller: 'AddContactToWorkflowDialogController',
                locals: {
                    contactId
                },
                bindToController: true
            }).then((result) => {

                if (result.workflow) {
                    const filter = {
                        condition: 'AND',
                        rules: [{
                            id: 'contact.id',
                            value: [contactId],
                            operator: 'equal'
                        }]
                    };

                    return workflowService.addToIndividualRecordFilter(result.workflow.id, filter).then(() => {

                        get();
                        utils.showSuccessToast('Contact added to workflow.');
                    });
                }
            });
        };

        vm.onTimelineRendered = () => {

            vm.timelineHasRendered = true;
        };

        vm.getEmailRelationshipString = () => {

            if (!vm.person) {
                return;
            }

            let text = `${vm.person.emails_sent >= 500 ? '500+' : vm.person.emails_sent} sent, ${vm.person.emails_received >= 500 ? '500+' : vm.person.emails_received} received`;

            if (vm.person.last_email_date) {
                text += `, last ${$filter('formatShortDate')(vm.person.last_email_date, true)}`;
            }

            return text;
        };

        vm.getStrongestConnectionTooltipLabel = () => {

            if (!vm.person) {
                return;
            }

            let tooltipLabel;

            if (vm.person.connection_strength < 0.2) {
                tooltipLabel = 'Strong connection';
            }
            else if (vm.person.connection_strength < 0.5) {
                tooltipLabel = 'Moderate connection';
            }
            else if (vm.person.connection_strength < 0.8) {
                tooltipLabel = 'Weak connection';
            }
            else {
                tooltipLabel = 'One-way connection';
            }

            return `${tooltipLabel} with ${vm.person.strongest_connection.name}`;
        };

        vm.getMeetingRelationshipString = () => {

            if (!vm.person) {
                return;
            }

            let text = `${vm.person.meetings_had} ${vm.person.meetings_had === 1 ? 'meeting' : 'meetings'}`;

            if (vm.person.last_meeting_date) {
                text += `, last ${$filter('formatShortDate')(vm.person.last_meeting_date, true)}`;
            }

            if (vm.person.next_meeting_date) {
                text += `, next ${$filter('formatShortDate')(vm.person.next_meeting_date, true)}`;
            }

            return text;
        };

        vm.getCallRelationshipString = () => {

            if (!vm.person) {
                return;
            }

            let text = `${vm.person.calls_had} ${vm.person.calls_had === 1 ? 'call' : 'calls'}`;

            if (vm.person.last_call_date) {
                text += `, last ${$filter('formatShortDate')(vm.person.last_call_date, true)}`;
            }

            if (vm.person.next_call_date) {
                text += `, next ${$filter('formatShortDate')(vm.person.next_call_date)}`;
            }

            return text;
        };

        function get() {

            vm.timelineHasRendered = false;

            return getPerson().then(function () {

                // Mutual contacts is already returned for network persons so no need to fetch again
                if (vm.person && vm.person.id === vm.personId && !vm.person.mutualContacts) {
                    return contact.getConnections(vm.personId).then(mutualContactsResponse, null, mutualContactsResponse);
                }
            });
        }

        function getPerson() {

            vm.showContactDetails = true;

            if (vm.type === 'contact') {
                // We manually handle the 404 to have a nicer UX for the no access flow
                return contact.get(vm.personId, { noToast: true, noToastForStatusCode: [404] }).then(personResponse, personErrorHandler, personResponse);
            }

            if (vm.type === 'user') {
                if (vm.personId !== model.me.id) {
                    vm.showEditButton = false;
                }

                return user.get(vm.personId, { noToast: true, noToastForStatusCode: [405] }).then(personResponse, personErrorHandler, personResponse);
            }

            if (vm.type === 'network') {
                vm.showEditButton = false;
                vm.showContactDetails = false;

                return contact.getNetworkContact(vm.personId).then(personResponse, null, personResponse);
            }
        }

        function personResponse(response) {

            // Reset empty state
            vm.showNoAccessEmptyState = false;

            vm.person = response.data;

            const oneMonthAgo = moment(new Date()).subtract(1, 'months');
            const isRecentlyEnriched = (oneMonthAgo.isBefore(new Date(vm.person.email_signature_sync_date)) || oneMonthAgo.isBefore(new Date(vm.person.public_info_sync_date)));

            if (!isRecentlyEnriched) {
                vm.lastEnriched = null;
                return;
            }

            if (new Date(vm.person.email_signature_sync_date).getTime() > new Date(vm.person.public_info_sync_date).getTime()) {
                vm.lastEnriched = 'Last enriched from an email signature ' + $filter('timeAgo')(vm.person.email_signature_sync_date, false);
            }
            else {
                vm.lastEnriched = 'Last enriched from publicly available info ' + $filter('timeAgo')(vm.person.public_info_sync_date, false);
            }
        }

        function personErrorHandler(response) {

            if ((response.status === 404 && response.data.message === 'You have no access') || (response.status === 405 && response.data.message === 'You have no access to the user')) {
                vm.showNoAccessEmptyState = true;

                vm.noAccessEmptyStateText = `You have no access to this ${vm.type}. Ask an admin${vm.type === 'contact' ? ' or contact owner' : ''}.`;
            }
        }


        function mutualContactsResponse(response) {

            vm.person.mutualContacts = response.data;
        }
    }
}());
