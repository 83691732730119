(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('opportunities', opportunitiesService);

    function opportunitiesService(sfHttp, config) {

        this.filterGet = function (search, filters, limit, offset, orderBy, downloadCSV, downloadExcel, options) {

            const filterUrl = config.apiUrl + 'opportunities';

            const request = {
                downloadCSV,
                downloadExcel,
                params: {
                    q: {
                        condition: 'AND',
                        rules: filters
                    }
                },
                noToast: true,
                noToastForStatusCode: [402, 422]
            };

            if (downloadCSV || downloadExcel) {
                request.params.details = true;
            }
            else {
                request.params.details = false;
            }

            if (options.exportFields) {
                request.params.export = options.exportFields;
            }

            if (search) {
                request.cache = false;
                request.ignoreLoadingBar = true;
                request.params.search = search === '' ? undefined : search;
            }

            if (limit) {
                request.params.limit = limit;
            }

            if (offset) {
                request.params.offset = offset;
            }

            if (orderBy) {
                request.params.order_by = orderBy;
            }

            if (options && options.returnCountOnly) {
                return sfHttp.head(filterUrl, request);
            }

            if (options && options.includeCount) {
                request.headers = { 'x-result-count': 'true' };
            }

            return sfHttp.get(filterUrl, request);
        };

        this.fieldsGet = function () {

            const fieldsUrl = config.apiUrl + 'opportunities/fields';

            const request = {
                params: {},
                noToast: true,
                noToastForStatusCode: [402, 422]
            };

            return sfHttp.get(fieldsUrl, request);
        };

        this.getStagesMetaInfo = function (search, filters) {

            const filterUrl = config.apiUrl + 'stages/meta';

            const request = {
                params: {
                    q: {
                        condition: 'AND',
                        rules: filters
                    }
                },
                noToast: true,
                noToastForStatusCode: [402, 422]
            };

            if (search) {
                request.params.search = search === '' ? undefined : search;
            }

            return sfHttp.get(filterUrl, request);
        };
    }
})();
