<div id="billingSettingsInvoice" data-flex data-layout="column">
    <mat-form-field>
        <mat-label>Country</mat-label>
        <input matInput [(ngModel)]="billingAddress.country"/>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Company Name</mat-label>
        <input matInput [(ngModel)]="billingAddress.company_name"/>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Street Address</mat-label>
        <input matInput [(ngModel)]="billingAddress.street"/>
    </mat-form-field>
    <mat-form-field>
        <mat-label>City</mat-label>
        <input matInput [(ngModel)]="billingAddress.city"/>
    </mat-form-field>
    <mat-form-field>
        <mat-label>State/Province/Region</mat-label>
        <input matInput [(ngModel)]="billingAddress.region"/>
    </mat-form-field>
    <mat-form-field>
        <mat-label>ZIP/Postal code</mat-label>
        <input matInput [(ngModel)]="billingAddress.zip"/>
    </mat-form-field>
    <mat-form-field>
        <mat-label>VAT number</mat-label>
        <input #vatInput="ngModel" matInput [(ngModel)]="billingAddress.vat" name="vat" pattern="^[a-zA-Z]{2}.+" type="text"/>

        <mat-hint *ngIf="vatMessage" [class]="vatMessage.class">
            {{ vatMessage.message }}
        </mat-hint>

    </mat-form-field>
    <mat-form-field class="md-block">
        <mat-label>Additional notes</mat-label>
        <textarea #notes matInput [(ngModel)]="billingAddress.notes" maxlength="150"></textarea>
        <mat-hint class="hint">Use this field for any additional information you'd like to include on your invoice.</mat-hint>
        <mat-hint align="end">{{ notes.value.length || 0 }} / 150</mat-hint>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Billing email address</mat-label>
        <input matInput [(ngModel)]="billingAddress.email"/>
    </mat-form-field>
    <div>
        <button id="saveButton" mat-button (click)="save()" [disabled]="processing || VAT.validatingNumber || firstLoad" type="submit" track-id="invoice-details">
            Save
        </button>
    </div>
</div>
